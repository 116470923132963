import Oidc from 'oidc-client';

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.WARN;

var port = window.location.port === 80 || window.location.port === 443 ? '' : window.location.port;
var rootUrl = window.location.protocol + '//' + window.location.hostname;
if (port !== '') rootUrl += ':' + port;

var userManager = new Oidc.UserManager({
    userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
    authority: process.env.VUE_APP_ID_SERVER_AUTHORITY,
    client_id: 'prismui-js',
    redirect_uri: rootUrl + '/signin-oidc',
    response_type: 'id_token token',
    scope: 'openid profile prism',
    post_logout_redirect_uri: rootUrl,
    silent_redirect_uri: rootUrl + '/silent-renew-callback',
    accessTokenExpiringNotificationTime: 10,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true
});

userManager.events.addAccessTokenExpired(function () {
    window.aptrinsic('reset');
    userManager.signoutRedirect();
});

userManager.gainsightIdentify = function() {
    userManager.getUser().then(function (user) {
        if (user) {
            const nameParts = user.profile.name.split(" ");
            window.aptrinsic('identify',
                {
                    'id': user.profile.sub,
                    'firstName': nameParts.shift(),
                    'lastName': nameParts.shift() || ""
                },
                {
                    'id': user.profile.tenant
                });
        }
    });
}

export default userManager;