<template>
    <div>
        <b-row cols="1" cols-sm="2" :cols-xl="isDateRangeVisible ?3 : 2">
            <b-col v-if="isDateRangeVisible">
                <p-select
                    label="Date Range"
                    :options="dateRangeDropdown"
                    value="Today"
                    @change="eventSetDates($event)"
                    />
            </b-col>
            <b-col>
                <p-datepicker
                    :label= "isDateRangeVisible ?'Start Date' : 'From Date'"
                    vid="startDate"
                    v-model="dateRangeOptions.fromDate"
                    :disabled="disabled"
                />
            </b-col>
            <b-col>
                <p-datepicker
                    :label= "isDateRangeVisible ?'End Date' : 'To Date'"
                    vid="endDate"
                    v-model="dateRangeOptions.toDate"
                    rules="dateGreaterOrEqual:@startDate"
                    :disabled="disabled"
                    is-end-date
                    />
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    name: 'DateRangeAdvanceTemplate',
    components: {},
    props: {
        dateRangeOptions: {
            fromDate: null,
            toDate: null,
        },
        isDateRangeVisible: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            disabled: true,
            dateRangeDropdown: [
                    { text: 'Today', value: 'Today' },
                    { text: 'Yesterday', value: 'Yesterday' },
                    { text: 'Previous 7 days', value: 'Previous7days' },
                    { text: 'This month', value: 'Thismonth' },
                    { text: 'Last month', value: 'Lastmonth' },
                    { text: 'Year-to-Date', value: 'yearToDate' },
                    { text: 'Last year', value: 'Lastyear' },
                    { text: 'Custom', value: 'Custom' },
            ]
        };
    },
    methods: {
        async loadData() {
            if(this.isDateRangeVisible){
                this.eventSetDates('Today');
            }else{
                this.eventSetDates('Custom');
                this.dateRangeOptions.fromDate = null;
                this.dateRangeOptions.toDate = null;
            }
        },
        eventSetDates(e) {
            const dateRanges = this.getDateRanges();
            this.dateRangeOptions.fromDate = (dateRanges[e].start).toISOString(); 
            this.dateRangeOptions.toDate = (dateRanges[e].end).toISOString(); 
            if(e == 'Custom'){
                this.disabled = false;
            }else{
                this.disabled = true;
            }
        },
        getDateRanges(){
            const today = new Date();
            const currentYear = today.getFullYear();
            const currentMonth = today.getMonth();
            const currentDate = today.getDate();

            const startOfToday =new Date();
            const endOfToday = new Date();

            const startOfYesterday = new Date();
            startOfYesterday.setDate(currentDate - 1);

            const endOfYesterday = new Date();
            endOfYesterday.setDate(currentDate - 1);

            const startOfPrevious7Days = new Date();
            startOfPrevious7Days.setDate(currentDate - 7);

            const endOfPrevious7Days = new Date();
            endOfPrevious7Days.setDate(currentDate - 1);

            const startOfCurrentMonth = new Date(currentYear, currentMonth);
            const endOfCurrentMonth = new Date();

            const startOfLastMonth = new Date(currentYear, currentMonth - 1);
            const endOfLastMonth = new Date(currentYear, currentMonth, 0);

            const startOfYear = new Date(currentYear, 0, 1);

            const startOfLastYear = new Date(currentYear - 1, 0, 1);
            const endOfLastYear = new Date(currentYear - 1, 11, 31);

            return {
                Today: { start: startOfToday, end: endOfToday },
                Yesterday: { start: startOfYesterday, end: endOfYesterday },
                Previous7days: { start: startOfPrevious7Days, end: endOfPrevious7Days },
                Thismonth: { start: startOfCurrentMonth, end: endOfCurrentMonth },
                Lastmonth: { start: startOfLastMonth, end: endOfLastMonth },
                yearToDate: { start: startOfYear, end: endOfToday },
                Lastyear: { start: startOfLastYear, end: endOfLastYear },
                Custom: { start: startOfToday, end: endOfToday }
            };
        }
    },
    mounted: async function() {
        await this.loadData();
    },
};
</script>
