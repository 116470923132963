<template>
    <ValidationProvider tag="div"
                        :rules="combinedRules"
                        :name="label || name"
                        :vid="vid"
                        :mode="mode"
                        v-slot="{ classes, errors }">
        <b-form-group :label-for="name || labelText"
                      :label="labelText">
            <template v-for="(_, name) in $scopedSlots"
                      :slot="name"
                      slot-scope="slotData">
                <slot :name="name" v-bind="slotData" />
            </template>
            <b-input-group>
                <PhoneNumber v-bind="$attrs"
                             ref="input"
                             @input="handleInput($event)"
                             v-on="$listeners"
                             :disabled="$attrs.disabled"
                             default-country-code="US"
                             show-code-on-list
                             :name="name"
                             :clearable="true"
                             :no-example="true"
                              color= "#6fa7d9"
                             :no-validator-state="true"
                             :no-country-selector ="true"
                             :border-radius = 0
                              :class="{
                        ...classes,
                        'input-group': true,
                              'validation-fail': errors.length > 0,
                    }"

                             :value="value"
                             @focus.native="$event.target.select()"></PhoneNumber>
                <template v-slot:append v-if="infoMessage">
                    <b-input-group-text class="px-1">
                        <b-icon-info-circle v-b-tooltip.hover
                                            :title="infoMessage"></b-icon-info-circle>
                    </b-input-group-text>
                </template>
                <template v-for="(_, name) in $scopedSlots"
                          :slot="name"
                          slot-scope="slotData">
                    <slot :name="name" v-bind="slotData" />
                </template>
            </b-input-group>

            <span>{{ errors[0] }}</span>
        </b-form-group>
    </ValidationProvider>
</template>
<script>
    import debounce from 'lodash/debounce';
    import baseInputs from '@/components/mixins/BaseInputs';
    import validatedInput from '@/components/mixins/ValidatedInput';
    import PhoneNumber from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    export default {
        name: 'p-phonenumber',
        mixins: [baseInputs, validatedInput],
        components: {
            PhoneNumber
        },
        data: function () {
            return {
                isInputActive: false,
            };
        },
        props: {
            value: [String],
            debounceWait: {
                type: Number,
                default: 0
            },

        },
        computed: {
            combinedRules() {
                if (this.rules.length) {
                    return this.rules + '|max:50';
                }
                return 'max:50';
            }
        },
        created() {
            if (this.debounceWait > 0) {
                this.handleInput = debounce(this.handleInput, this.debounceWait);
            }
        },
        methods: {
            focus: function () {
                this.$refs.input.$el.focus();
            }
        }
    };
</script>

<style scoped lang="scss">
    @import '@/styles/app/common/variables.scss';

/deep/.input-tel.has-value .input-tel__label {
    display: none;
}
/deep/.input-tel__clear{
    margin-top:5px;
}
/deep/.input-tel__clear:hover {
    color: $gray-darker !important;
}
/deep/.input-tel.has-value .input-tel__input {
     padding-top: 0px;
}
/deep/.input-tel.has-hint .input-tel__input {
    padding-bottom: 4px;
}
/deep/ .input-tel__input::placeholder {
  color:transparent;
}
/deep/ input.input-tel__input[style]:hover,:focus {
     border: 1px solid $input-focus-border-color !important;
}

/deep/.input-tel__clear__effect{
    background:none;
}
/deep/ .validation-fail input.input-tel__input[style] {
    border-color: $danger !important;
}
/deep/ input.input-tel__input[style]{
    box-shadow: 0px 0px 0px !important;
    padding-right: 35px;
    min-height:2.1875rem;
    height:2.1875rem;
    cursor: text;
    caret-color:#515a61 !important;
    border: 1px solid $input-default-border-color !important;

}
/deep/ input.input-tel__input[style]:disabled {
     border: 1px solid $input-border-color !important;
}

</style>
