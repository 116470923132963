import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import store from '@/store/store.js';

export default {
    data() {
        return {
            //over-ride this property with lists you want to watch to standardize and avoid duplicating this code in multiple files.
            //any property listed inside selectListOptions will be loaded from the store and kept up to date
            selectListOptions: {}
        };
    },
    async created() {
        this.unsubscribe = store.subscribe((mutation) => {
            if(!mutation.type.indexOf('update') === 0){
                return;
            }

            let listName = mutation.type.split('update')[1];
            if(listName == undefined){
                return;
            }
            listName = listName.charAt(0).toLowerCase() + listName.slice(1);

            if (this.selectListOptions[listName] != undefined && mutation.payload.length > 0) {
                this.selectListOptions[listName] = mutation.payload;
            }
        });
        for (var listName in this.selectListOptions) {
            await this.loadList(listName);
        }
    },
    beforeDestroy() {
        this.unsubscribe();
    },
    methods: {
        async loadList(listName) {
            this.selectListOptions[listName] = await selectListOptionsDataContext.getStoreDropdownData(listName);
        }
    }
};
