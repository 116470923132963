<template>
    <div>
        <vue-dropzone
            id="my-dropzone"
            :options="dropzoneOptions"
            ref="dropzoneInstance"
            @vdropzone-file-added="attachListener"
            @vdropzone-removed-file='removeListener'
            :class="{'small' : small}"
        >
        </vue-dropzone>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import baseInputs from '@/components/mixins/BaseInputs';

export default {
    name: 'p-image',
    mixins: [baseInputs],
    components: {
        vueDropzone: vue2Dropzone
    },
    props: {
        value: [Object, File],
        small: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            self: this,
            newURL: null,
            dropzoneOptions: {
                url: '/upload', //todo, handle upload
                autoProcessQueue: false,
                uploadMultiple: false,
                parallelUploads: 1,
                maxFiles: 1,
                thumbnailMethod: 'contain',
                dictDefaultMessage:
                    '<em class="fa fa-upload text-muted"></em><br>Drop image to upload', // default messages before first drop
                dictRemoveFile:
                    '<em class="fa fa-times-circle text-muted text-danger" ></em><br>',
                paramName: 'file', // The name that will be used to transfer the file
                maxFilesize: 6, // MB
                addRemoveLinks: true,
                acceptedFiles: 'image/*',
                thumbnailWidth: this.small ? 85 : 220,
                thumbnailHeight: this.small ? 85 : 220,
                accept: (file, done) => {
                    file.index = this.value?.index ?? file.index;
                    this.setURL(file);
                    this.handleInput(file);
                    done();
                },
                init: function() {
                    this.on('addedfile', function() {
                        if (this.files.length > 1) {
                            this.removeFile(this.files[0]);
                        }
                    });
                }
            }
        };
    },
    watch: {
        newURL: {
            handler(value) {
                this.$emit('newURL', value);
            }
        }
    },
    methods: {
        setURL(file) {
            var reader = new FileReader();
            let that = this;
            reader.onload = function(event) {
                    // event.target.result contains base64 encoded image
                    that.newURL = event.target.result;
                };
            reader.readAsDataURL(file);
        },
        attachListener: function(file) {
            //this lets the user click on the image to replace it
            file.previewElement.addEventListener('click', () => {
                this.$refs.dropzoneInstance.$el.click();
            });
        },
        removeListener: function()
        {
            if(this.value)
            {
                this.$emit('newURL', null);
            }
        }
    },
    mounted() {
        if (this.value?.dataURL) {
            this.value.dataURL = unescape(this.value.dataURL)
            let dropzone = this.$refs.dropzoneInstance;
            dropzone.manuallyAddFile(this.value, this.value.dataURL);
        }
    }
};
</script>

<style scoped lang="scss">
@import '@/styles/app/common/variables.scss';
/deep/ .vue-dropzone {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 277px;
    border: 1px solid $input-default-border-color !important;

    .dz-preview {
        position: relative;
        padding: 0.5rem;
    }

    &.dz-started .dz-message {
        display: none;
    }

    .dz-default.dz-message {
        font-size: 20px;
        min-height: 200px;
        text-align: center;
        padding-top: 30px;
        cursor: pointer;
    }

    .dz-error-mark,
    .dz-success-mark {
        position: absolute;
        display: none;
        top: 2.5rem;
        left: 2.5rem;
    }
    .dz-error .dz-error-mark {
        display: block;
    }
    .dz-size,
    .dz-filename {
        // hide the file name and file size that is otherwise
        // displayed below the image
        display: none;
    }
    .dz-remove {
        position: absolute;
        top: 0px;
        left: 2px;
        font-size: large;
        //radial white background to make the x in the (x) white
        //instead of transparent
        background: radial-gradient(white 40%, transparent 40%);
    }

    .dz-image > img {
        max-width: 100%;
        display: block;
        cursor: pointer;
    }
}
/deep/ .small {
    width: 89px;

    .dz-default.dz-message {
        font-size: 10px;
        min-height: 86px;
        text-align: center;
        padding-top: 15px;
        cursor: pointer;
    }

    .dz-image > img {
        max-width: 89px;
        display: block;
        cursor: pointer;
    }
}

</style>
