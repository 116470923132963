<template>
    <div>
        <p-input :label="label" :value="addressString"
                    :disabled="disabled" readonly v-if="!textArea">
            <template v-slot:append>
                <p-button :is-busy="isBusy" @click="showEdit" class="pl-0">
                    <i class="fas fa-edit"></i
                ></p-button>

            </template>
        </p-input>
        <p-text-area :value="addressString"
                    :disabled="disabled" readonly v-else>
            <template v-slot:editButton>
                <div><strong>{{label}}</strong>
                <p-button :is-busy="isBusy" @click="showEdit" no-border class="pl-2 pb-2 d-inline">
                    <i class="fas fa-edit"></i
                ></p-button>
                </div>
            </template>
        </p-text-area>
        <p-modal :name="name" :label="label" :ref="name" @ok="saveUpdate" :button-busy="isBusy">
            <p-form ref="addressForm">
                <p-address
                    v-model="editAddress"
                    v-bind="$attrs"
                    full-width
                    :disabled="disabled"
                    :sameAsAddressName="sameAsAddressName"
                    :sameAsAddress="sameAsAddress"
                >

                    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
                </p-address>
            </p-form>
        </p-modal>
    </div>
</template>
<script>
import baseInputs from '@/components/mixins/BaseInputs';
import Addresses from '@/components/mixins/Addresses.js';
export default {
    inheritAttrs: false,
    name: 'p-address-input',
    mixins: [baseInputs, Addresses],
    props: {
        value: [Object],
        disabled: {
            type: Boolean,
            default: false
        },        
        textArea: {
            type: Boolean,
            default: false
        },
        isBusy: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            editAddress: {}
        };
    },

    methods: {
        showEdit() {
            this.editAddress = { ...this.value };
            this.$refs[this.name].show();
        },
        async saveUpdate(bvModalEvt) {
            bvModalEvt.preventDefault();

            if (!(await this.$refs.addressForm.validate())) {
                return;
            }            
            this.handleInput(this.editAddress);
            this.$refs[this.name].hide();
            this.$emit('updateShipping');
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/styles/app/common/variables.scss";
/deep/  .form-control[readonly]:not([disabled]){
    background-color: white;
}
/deep/ textarea {
    resize:none
}

</style>
