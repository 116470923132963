import axios from 'axios';

const state = {
    featureFlags: []
};

const getters = {
    featureFlagEnabled: state => flag => {
        return state.featureFlags.find(f => f == flag);
    }
};

const actions = {
    async fetchFeatureFlags({ commit }) {
        //get from local storage so we have something until response comes back. prevents laggy redering of menu items behind feature flags.
        if(localStorage.getItem('featureFlags')){
            try {
                var flags = JSON.parse(localStorage.getItem('featureFlags'));
                commit('setFeatureFlags', flags);
            } 
            catch {
                localStorage.removeItem('featureFlags');
            }
        }

        var response = await axios.get('featureflags?client=Mosaic');
        commit('setFeatureFlags', response.data);
        localStorage.setItem('featureFlags', JSON.stringify(response.data));
    }
};

const mutations = {
    setFeatureFlags: (state, flags) => (state.featureFlags = flags)
};

export default {
    state,
    getters,
    actions,
    mutations
};
