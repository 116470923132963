<template>
    <div>
        <b-row>
            <b-col
                cols="auto"
            >
                <b-container>
                    <slot name="title-line"></slot>
                    <SelectionActions
                        class="d-inline-block"
                        v-if="selectionActionsInTitle == true"
                        :selection-actions="selectionActions"
                        :selected-row-count="selectedRows.length"
                        @action-clicked="handleActionClicked"
                    >
                    </SelectionActions>
                </b-container>
            </b-col>
        </b-row>
        <b-row>
            <b-col v-if="includeAutomaticSearch">
                <p-input
                    type="search"
                    v-model="automaticSearch"
                    :placeholder="searchMessageComputed"
                    @input="handleSearchInput"
                ></p-input>
            </b-col>
            <b-col v-if="includeAutomaticSearch && includeDropdown" cols="4">
                <p-select
                    v-model="searchBy"
                    :value="null"
                    :options="searchFields"
                    text-field="label"
                    value-field="label"
                    noSelectionMessage="All"
                />
            </b-col>
            <b-col
                cols="auto"
                v-if="showTopPerPageSelector && filteredRecordCount > minPerPage"
            >
                <p-select :options="perPageOptions" v-model="localPerPage" />
            </b-col>
            <b-col
                cols="auto"
                v-if="showPerPageSelector && filteredRecordCount > minPerPage && showTopPagination">
                <b-pagination
                    v-if="pagination && filteredRecordCount > localPerPage"
                    v-model="currentPage"
                    :total-rows="filteredRecordCount"
                    :per-page="localPerPage"
                ></b-pagination>
            </b-col>
            <SelectionActions
                class="d-inline-block"
                v-if="selectionActionsInTitle == false"
                :selection-actions="selectionActions"
                :selectedRowCount="selectedRows.length"
                @action-clicked="handleActionClicked"
            >
            </SelectionActions>
        </b-row>
        <b-row>
            <b-col>
                <slot name="above-table"> </slot>
            </b-col>
        </b-row>
        <b-row class="px-2">
            <b-table
                v-bind="$attrs"
                responsive
                striped
                hover
                small
                :busy="isBusy"
                :show-empty="showEmpty"
                ref="selectableTable"
                no-sort-reset
                select-mode="range"
                :items="filteredItems"
                :empty-text="emptyText"
                :current-page="currentPage"
                @refreshed="updateSelectAll"
                :per-page="localPerPage"
                :sortBy="sortBy"
                :sortDesc="sortDesc"
                :fields="calculatedFields"
                :search-destination="searchDestination"
                @row-clicked="
                    e => enableRowSelection && !disableRowCheck(e) && rowClicked
                "
                @context-changed="$emit('context-changed', $event)"
                sort-icon-left
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                @sort-changed="sortChanged"
            >
                <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
                <template
                    v-for="(_, name) in $scopedSlots"
                    :slot="name"
                    slot-scope="slotData"
                    ><slot :name="name" v-bind="slotData" />
                </template>
                <template v-slot:empty="scope">
                    {{ scope.emptyText }}
                </template>
                <template v-slot:head(selected)="data">
                    <p-checkbox
                        v-if="!singleSelect && showSelectAll"
                        :value="allSelected"
                        @change="toggleSelectAll"
                        :label="selectionLabel"
                    ></p-checkbox>
                    <span v-else-if ="singleSelect">{{ singleSelectButtonLabel }}</span>
                </template>
                <template v-slot:cell(selected)="{ value, item }">
                    <p-checkbox
                        v-if="!singleSelect"
                        :value="isSelected(item)"
                        @change="onSelection(item)"
                        :disabled="disableRowCheck(item)"
                    ></p-checkbox>
                    <p-button v-else size="sm" @click="addSelection(item)">{{
                        singleSelectButtonLabel
                    }}</p-button>
                </template>
            </b-table>
        </b-row>
        <b-row v-if='showRecordCount' :class="{ 'busy-table': isBusy }">
            <b-col class="d-flex pb-0 pt-0">
            <div class="mt-2 ml-2">{{ currentPageRecordCount }} of {{recordCount}} Items</div>
                <div class="ml-auto mr-3">
                    <p-select
                        v-model="localPerPage"
                        v-if="
                            showPerPageSelector &&
                                filteredRecordCount > minPerPage
                        "
                        :options="perPageOptions"
                    >
                    </p-select>
                </div>
                <b-pagination
                    v-if="pagination && filteredRecordCount > localPerPage"
                    v-model="currentPage"
                    :total-rows="filteredRecordCount"
                    :per-page="localPerPage"
                ></b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { textIncludes, searchPlaceHolderFormatter } from '@/components/Common/Formatters.js';
import SelectionActions from '@/components/SelectionActions.vue';

export default {
    name: 'p-table',
    inheritAttrs: false,
    props: {
        items: Array,
        sortBy: String,
        sortDesc: Boolean,
        pagination: {
            type: Boolean,
            default: true
        },
        isSelectedFunction: {
            type: Function,
            default: undefined
        },
        emptyText: String,
        perPage: {
            type: Number,
            default: 10
        },
        showPerPageSelector: {
            type: Boolean,
            default: false
        },
        showTopPerPageSelector: {
            type: Boolean,
            default: false
        },
        searchDestination: {
            type: String,
            default: null
        },
        selectable: {
            type: Boolean,
            default: false
        },
        selectionActions: {
            type: Array,
            default: () => []
        },
        selectionActionsInTitle: {
            type: Boolean,
            default: false
        },
        fields: {
            type: Array,
            default: () => null
        },
        singleSelect: {
            type: Boolean,
            default: false
        },
        singleSelectButtonLabel: {
            type: String,
            default: 'Add'
        },
        disableRowCheck: {
            type: Function,
            default: () => false
        },
        enableRowSelection: {
            type: Boolean,
            default: true
        },
        showRecordCount: {
            type: Boolean,
            default: true
        },
        showEmpty: {
            type: Boolean,
            default: true
        },
        isBusy: {
            type: Boolean,
            default: false
        },
         showSelectAll: {
            type: Boolean,
            default: true
        },
        showTopPagination: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        visibleFields() {
            return this.fields.filter(x => !x.hidden);
        },
        calculatedFields() {
            if (this.enableRowSelection) {
                return [
                    {
                        label: '',
                        key: 'selected',
                        sortable: false
                    },
                    ...this.visibleFields
                ];
            }

            return this.visibleFields;
        },
        includeAutomaticSearch() {
            return (
                this.searchDestination === null && this.searchFields.length > 0
            );
        },
        searchFields() {
            return this.fields.filter(x => x.automaticSearch);
        },
        recordCount() {
            return this.items?.length ?? 0;
        },
        filteredRecordCount() {
            return this.filteredItems?.length ?? 0;
        },
        filteredItems() {
            if (!this.includeAutomaticSearch || this.automaticSearch === '') {
                return this.items;
            }
            return this.items.filter(x => this.itemPassesFilter(x));
        },
        selectionLabel() {
            return this.selectedRows.length > 0
                ? `(${this.selectedRows.length})`
                : '';
        },
        computedPerPage() {
            return this.localPerPage;
        },
        minPerPage() {
            return this.perPageOptions[0].value;
        },

        searchByLabels(){
            //falling back to key when label is not present to avoid showing 'null'.
            //We should avoid not providing a label on search fields because key
            //isn't formatted for user display (invoiceNumber instead of Invoice Number for example)
            return this.searchFields.map(x => x.label || x.key);
        },

        searchMessageComputed() {
            return searchPlaceHolderFormatter(this.searchByLabels,this.searchBy);
        },
        currentPageRecordCount(){
            let maxRecords = this.currentPage * this.computedPerPage;
            let result = this.computedPerPage;

            if(maxRecords > this.recordCount)
            {
                let overCount = maxRecords - this.recordCount;
                result = this.computedPerPage - overCount;
            }

            if(this.filteredRecordCount < this.computedPerPage)
            {
                result = this.filteredRecordCount;
            }

            return result;
        },
        includeDropdown(){
            return this.searchFields.length > 1;
        }
    },
    data() {
        return {
            currentPage: 1,
            selectedRows: [],
            automaticSearch: '',
            allSelected: false,
            perPageOptions: [
                { value: 10, text: 'Show 10' },
                { value: 25, text: 'Show 25' },
                { value: 50, text: 'Show 50' },
                { value: 100, text: 'Show 100' },
                { value: 500, text: 'Show 500' }
            ],
            localPerPage: 10,
            searchBy: null
        };
    },
    created() {
        this.localPerPage = this.perPage;

        if(this.items != null)
        {
            this.items.forEach(x => {this.isSelected(x)})
        }
    },
    methods: {
        sortChanged: function(ctx) {
            this.$emit('sort-by-change', ctx.sortBy);
            this.$emit('sort-desc-change', ctx.sortDesc);
        },
        updateSelectAll() {
            this.allSelected = this.filteredItems
                ?.filter(x => !this.disableRowCheck?.(x))
                .every(i => this.selectedRows.includes(i));
        },
        toggleSelectAll() {
            this.selectedRows = this.allSelected
                ? []
                : [
                      ...this.filteredItems.filter(
                          x => !this.disableRowCheck?.(x)
                      )
                  ];
            this.$emit('input', this.selectedRows);
        },
        selectAll() {
            this.selectedRows = [
                      ...this.filteredItems.filter(
                          x => !this.disableRowCheck?.(x)
                      )
                  ];
            this.$emit('input', this.selectedRows);
        },
        unselectAll() {
            this.selectedRows = [];
            this.$emit('input', this.selectedRows);
        },
        isSelected(item) {
            this.updateSelectAll();
            if (this.isSelectedFunction) {
                let itemSelected = this.isSelectedFunction(item);
                if (itemSelected && !this.selectedRows.some(x => x === item)) {
                    this.selectedRows.push(item);
                }
                return this.isSelectedFunction(item);
            }
            return this.selectedRows.some(x => x === item);
        },
        itemPassesFilter(item) {
            let search;
            if (this.searchBy != null) {
                search = this.searchFields.filter(y => y.label === this.searchBy);

            } else {
                search = this.searchFields;
            }
            
           return search.some(x =>
                    textIncludes(x.key.split('.')
                      .reduce((p,c) => (x.formatter != null && x.formatter(p[c])) || (p && p[c]) || null, item ) + '', this.automaticSearch));
        },
        rowClicked(element) {
            this.$emit('row-clicked', element);
            this.$emit('input', this.selectedRows);
        },
        handleSearchInput() {
            this.currentPage = 1;
        },
        addSelections(items) {
            items.forEach(x => {
                if(!this.isSelected(x) && this.items.some(y => y === x)){
                    this.selectedRows.push(x);
                }
            })
            this.$emit('input', this.selectedRows);
            this.$emit('submit-selection');
        },
        addSelection(item) {
            if(!this.isSelected(item)  && this.items.find(y => y === item)){
                this.selectedRows.push(item);
            }
            this.$emit('input', this.selectedRows);
            this.$emit('submit-selection');
        },
        onSelection(item) {
            var existingIndex = this.selectedRows.findIndex(x => x === item);
            if (existingIndex > -1) {
                this.selectedRows.splice(existingIndex, 1);
            } else {
                this.selectedRows.push(item);
            }
            this.$emit('input', this.selectedRows);
        },
        handleActionClicked(action) {
            action(this.selectedRows);
        }
    },
    watch: {
        localPerPage: function() {
            this.currentPage = 1;
        },
        items: function() {
            //if any items are removed and they are also in selectedRows, remove them
            let previousSelectionCount = this.selectedRows.length;
            this.selectedRows = this.selectedRows.filter(x =>
                this.items.find(y => y === x)
            );
            if(previousSelectionCount > this.selectedRows.length){
                this.$emit('input', this.selectedRows);
            }
            if(this.filteredItems.length === 0){
                //avoid setting currentPage to 0, <b-pagination> does not allow it.
                this.currentPage = 1;
            }
            //if current page is greater than the last page, change to the last page
            else if (this.currentPage > Math.ceil(this.filteredItems.length / this.perPage)) {
                this.currentPage = Math.ceil(
                    this.filteredItems.length / this.perPage
                );
            }
        },
        filteredItems : function(){
            this.$emit('updated-table-data',this.filteredItems);
        }
    },
    components: {
        SelectionActions
    }
};
</script>

<style scoped>
>>> .table-responsive {
    overflow-y: visible;
    overflow-x: auto;
}
>>> th, td {
    max-width: 500px;
}
>>> .mw120 {
    max-width: 120px;
}
</style>

<style scoped lang="scss">
@import '@/styles/app.scss';
/deep/ tr.b-table-row-selected {
    border: 2px solid $Selected-input-color;
}
/deep/ .busy-table {
    opacity: 50%;
}
/deep/ .noWrap {
    white-space: nowrap;
}

</style>
