<template>
    <p-card :class="primaryBg + ' border-0'" no-body>
        <b-row class="align-items-center mx-0">
            <b-col cols="4" class="py-3 text-center" :style="'border-right:'+borderValue">
                <slot></slot>
            </b-col>
            <b-col cols="8" :class="'py-4 ' + secondaryBg + ' rounded-right'">
                <h3
                    class="m-0 text-bold"
                    :style="'color:' + primaryTextColor"
                >
                    {{ primaryText }}
                </h3>
                <div
                    :style="'font-size:13px;color:' + secondaryTextColor"
                >
                    {{ secondaryText }}
                </div>
            </b-col>
        </b-row>
    </p-card>
</template>
<script>
export default {
    name: 'p-box-widget',
    props: {
        primaryBg: String,
        secondaryBg: String,
        primaryText: String,
        secondaryText: String,
        primaryTextColor: String,
        secondaryTextColor: String,
        border: {
            type: Boolean,
            default: false
        }
    },
    computed: {
      borderValue(){
        return this.border?'4px inset':'none';
      }
    }
};
</script>

