<template>
    <b-card class="b" v-if="collapseEnabled">
        <b-card-body>
            <b-card-title>
                <span :class="titleClass">{{ $attrs.title }}</span>
                <b-button
                    v-b-toggle="uniqueId"
                    class="collapse-button p-0 float-right"
                >
                    <b-icon-chevron-down
                        class="when-open mb-1"
                    ></b-icon-chevron-down>
                    <b-icon-chevron-right
                        class="when-closed mb-1"
                    ></b-icon-chevron-right>
                </b-button>
            </b-card-title>
            <b-collapse :id="uniqueId" v-model="isExpanded">
                <slot></slot>
            </b-collapse>
        </b-card-body>
    </b-card>

    <b-card v-else class="b" v-bind="$attrs">
        <slot></slot>
    </b-card>
</template>

<script>
import uniqueid from 'lodash.uniqueid';
export default {
    props: {
        collapseEnabled: {
            type: Boolean,
            default: false
        },
        startExpanded: {
            type: Boolean,
            default: false
        },
        titleClass: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            isExpanded: true
        };
    },
    computed: {
        uniqueId() {
            return uniqueid('toggle_');
        }
    },
    created() {
        this.isExpanded = this.startExpanded;
    }
};
</script>

<style scoped lang="scss">
@import '@/styles/app/common/variables.scss';

/deep/ .collapsed > .when-open,
.not-collapsed > .when-closed {
    display: none;
}
/deep/ .collapse-button {
    background-color: inherit;
    color: $body-color;
    border: none;
}
</style>
