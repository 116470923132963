import StoreListManager from '@/components/mixins/StoreListManager.js';
import { addressFormatter } from '@/components/Common/Formatters.js';

export default {
    inheritAttrs: false,
    mixins: [StoreListManager],
    props: {
        //default behavior is to not check same as checkbox when both addresses are empty.
        //set this to true if we want to reverse that behavior
        useSameAsWhenEmpty: {
            type: Boolean,
            default: false
        },
        sameAsAddressName: {
            type: String,
            default: 'shipping'
        },
        sameAsAddress: {
            type: Object,
            default: null
        },

        //linker object that may have additional address properties (vendor address)
        linkerAddress: {
            type: Object,
            default: null
        },
        sameAsLinkerAddress: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            selectListOptions: {
                states: []
            }
        };
    },
    computed: {
        addressString() {
            if (this.value.useSameAs === true) {
                return `Same as ${this.sameAsAddressName}`;
            }

            return addressFormatter(this.value, this.$store);
        },
    },
    watch: {
        sameAsAddress: {
            handler: function() {
                this.syncSameAsAddress();
            }
        }, 
        sameAsLinkerAddress: {
            handler: function() {
                this.syncSameAsAddress();
            },
            deep: true
        }, 
        'value': function() {
            //needed when the page is saved and address objects
            //are reloaded from the api return
            this.updateUseSameAs();
        },
    },
    mounted: async function() {
        this.updateUseSameAs();
    },
    methods: {
        updateUseSameAs(){
            if(this.value.useSameAs === true || this.value.useSameAs === false){
                return;
            }
            let sameAs = 
                (this.value.useSameAs !== false &&
                    this.sameAsAddress &&
                    //don't load page with same as checked no addresses are configured (due to matching null values)
                    (this.useSameAsWhenEmpty || !this.isEmpty(this.sameAsAddress)) &&
                    this.isEqual()) === true;

            this.update('useSameAs', sameAs);
        },
        syncSameAsAddress() {
            if (this.value.useSameAs) {
                this.$emit('input', { ...this.sameAsAddress, useSameAs: true });
                if(this.sameAsLinkerAddress){
                    this.$emit('syncLinkerAddress', { ...this.sameAsLinkerAddress });
                }
            }
        },
        update(key, value) {
            let updateDto = { ...this.value, [key]: value };
            if (key === 'stateId' && value != null) {
                //updating state/country in same event to avoid the second change
                //over-riding the first's update
                //(previously the state change, triggered as seperate country change event.)
                updateDto.countryId = this.selectListOptions.states.find(
                    x => x.value === value
                ).filter;
            }
            this.$emit('input', updateDto);
        },

        isEqual() {
            return (
                this.value.description === this.sameAsAddress.description &&
                this.value.street === this.sameAsAddress.street &&
                this.value.city === this.sameAsAddress.city &&
                this.value.stateId === this.sameAsAddress.stateId &&
                this.value.postalCode === this.sameAsAddress.postalCode &&

                this.linkerAddress?.phoneNumber === this.sameAsLinkerAddress?.phoneNumber &&
                this.linkerAddress?.faxNumber === this.sameAsLinkerAddress?.faxNumber
                
            );
        },

        isEmpty(address) {
            return (
                address.description == null &&
                address.street == null &&
                address.city == null &&
                address.stateId == null &&
                address.postalCode == null &&

                this.linkerAddress?.phoneNumber == null &&
                this.linkerAddress?.faxNumber == null
            );
        }
    }
};
