<template>
    <ValidationProvider
        tag="div"
        :rules="rules"
        :name="label || name"
        :vid="vid"
        :mode="mode"
        v-slot="{ classes, errors }"
    >
        <b-form-group
            :label-for="name || labelText"
            :label="labelText"
            :class="{
                'mb-0': compactFormat,
                'compact-container': compactFormat
            }"
        >
            <template
                v-for="(_, name) in $scopedSlots"
                :slot="name"
                slot-scope="slotData"
            >
                <slot :name="name" v-bind="slotData" />
            </template>
            <b-input-group>
                <b-form-input
                    v-bind="$attrs"
                    ref="input"
                    :name="name"
                    :value="value"
                    @input="handleInput($event)"
                    step="any"
                    v-on="$listeners"
                    :class="{ ...classes, 'compact-control': compactFormat }"
                ></b-form-input>
                <template v-slot:append v-if="infoMessage">
                    <b-input-group-text class="px-1">
                        <b-icon-info-circle
                            v-b-tooltip.hover
                            :title="infoMessage"
                        ></b-icon-info-circle>
                    </b-input-group-text>
                </template>
                <template
                    v-for="(_, name) in $scopedSlots"
                    :slot="name"
                    slot-scope="slotData"
                >
                    <slot :name="name" v-bind="slotData" />
                </template>
            </b-input-group>
            <span>{{ errors[0] }}</span>
        </b-form-group>
    </ValidationProvider>
</template>

<script>
import debounce from 'lodash/debounce';
import baseInputs from '@/components/mixins/BaseInputs';
import validatedInput from '@/components/mixins/ValidatedInput';

export default {
    name: 'p-input',
    mixins: [baseInputs, validatedInput],
    props: {
        value: [String, Number],
        debounceWait: {
            type: Number,
            default: 0
        },
        compactFormat: {
            type: Boolean,
            default: false
        }
    },
    created() {
        if (this.debounceWait > 0) {
            this.handleInput = debounce(this.handleInput, this.debounceWait);
        }
    },
    methods: {
        focus: function() {
            this.$refs.input.focus();
        }
    }
};
</script>

<style scoped lang="scss">
/deep/ .compact-control {
    padding-bottom: 5px;
    padding-top: 8px;
    padding-left: 2px;
    height: 1.5rem;
}
</style>

