import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import userManager from './services/SecurityService';

//import Vuetify from 'vuetify'

import './vendor.js';

import App from './App.vue';
import router from './router';
import store from './store/store';

import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

Vue.config.productionTip = false;

import Toasted from 'vue-toasted';
Vue.use(Toasted, {
    position: 'top-center',
    keepOnHover: true,
    containerClass: 'toast-container'
});

import VueCurrencyInput from 'vue-currency-input';
import fileReader from  "@/services/fileReader.js";

const pluginOptions = {
    /* see config reference */
    globalOptions: { currency: {prefix:'$'} }
};
Vue.use(VueCurrencyInput, pluginOptions);

userManager.gainsightIdentify();

Vue.toasted.register(
    'app_error',
    payload => {
        // if there is no message passed show default message
        if (!payload) {
            return 'Oops... Something went wrong.';
        }

        if (typeof payload === 'string') {
            return payload;
        }

        if (payload.correlationId) {
            // an error from exception middleware
            var stackTrace = '';
            if (payload.stackTrace) {
                stackTrace = `<br /> ${payload.stackTrace}`;
            }
            return `${payload.message} ${stackTrace} <br /> <br /> Error ID: ${payload.correlationId}`;
        }

        // if there is a message show it with the message.
        // parse the .net modelstate or http 400 response
        var msg = '';
        payload = payload.errors || payload;
        for (var key in payload) {
            if (payload.hasOwnProperty(key)) {
                for (var i = 0; i < payload[key].length; i++) {
                    msg += payload[key][i] + '<br />';
                }
            }
        }

        if (msg.length === 0) {
            msg = 'Oops... Something went wrong.';
        }

        return msg;
    },
    {
        type: 'error',
        //icon: 'error_outline',
        action: [
            {
                text: 'Copy',
                onClick: (e, toastObject) => {
                  var textArea = document.createElement("textarea");
                  textArea.value = toastObject.el.firstChild.textContent;

                  // Avoid scrolling to bottom
                  textArea.style.top = "0";
                  textArea.style.left = "0";
                  textArea.style.position = "fixed";

                  document.body.appendChild(textArea);
                  textArea.focus();
                  textArea.select();
                  document.execCommand('copy');
                }
            },
            {
                text: 'Close',
                onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                }
            }
        ]
    }
);

Vue.toasted.register(
    'app_success',
    payload => {
        // if there is no message passed show default message
        if (!payload) {
            return 'Success!';
        }

        // if there is a message show it with the message
        return payload;
    },
    {
        type: 'success',
        //icon: 'error_outline',
        action: {
            text: 'Close',
            onClick: (e, toastObject) => {
                toastObject.goAway(0);
            }
        }
    }
);

import signalR from './services/SignalR';

Vue.use(signalR, { baseURL: process.env.VUE_APP_API_ROOT_URL });

import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
router.beforeEach(async (to, from, next) => {
    const allowAnonymous = to.matched.some(
        record => record.meta.allowAnonymous
    );

    const isLocationFiltered = to.matched.some(
        record => record.meta.singleLocationFilter
    );

    if (allowAnonymous) {
        next();
    } else {
        userManager.getUser().then(async function(user) {
            if (user) {
                //add user to vuex
                if(store.getters.getCurrentUser === null){
                    store.commit('setCurrentUser', user);
                    Vue.prototype.startSignalR();
                }

                if(isLocationFiltered) {
                    if (store.getters.getSingleLocationId === null ) {
                        await axios.get('selectlists/singlelocationid').then((resp) => {
                            store.commit('updateSingleLocationId', resp.data);
                        });
                    }
                    let userId = store.getters.getCurrentUserId;
                    let localStorageLocationId = localStorage.getItem('SelectedLocationId' + userId);
                    let selectedLocationId = localStorageLocationId ?? await selectListOptionsDataContext.getSelectedLocationIdAsync();
                    if (selectedLocationId != store.getters.getSingleLocationId) {
                        Vue.toasted.global.app_error(
                            'This section of Mosaic is not accessible to this location.'
                        );
                        next({ 
                                path: '/', 
                                replace: true 
                            });        
                    }
                    else {
                        next();
                    }
                }
                else {
                    next();
                }              
            } else {
                Vue.prototype.stopSignalR();

                //clear out stale state entries before redirect to prevent login issues
                userManager.clearStaleState().then(() => {
                    //redirect to sign in page
                    userManager.signinRedirect({ state: to.path }).catch(err => {
                        Vue.toasted.global.app_error(
                            'Unable to redirect to sso login: ' + err.message
                        );
                    });
                });

            }
        });
    }
});

router.afterEach(to => {
    Vue.nextTick(() => {
        if (to.meta && to.meta.title) {
            document.title = to.meta.title + ' - Mosaic';
        }
    });
});

import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_API_ROOT_URL;

axios.interceptors.request.use(config => {
    return userManager.getUser().then(function(user) {
        if (user) {
            config.headers.authorization = 'Bearer ' + user.access_token;
            return config;
        }

        return null;
    });
});

axios.interceptors.response.use(
    res => {
        return res;
    },
    err => {
        if(err.response)
        {
            switch(err.response.status){
                case 403:
                    router.push('Error/Unauthorized');
                    break;
                default:
                    if(err.config.responseType === 'blob'){

                        fileReader(err.response.data).then(resp => {
                            Vue.toasted.global.app_error(JSON.parse(resp));
                        });
                    }
                    else{
                        Vue.toasted.global.app_error(err.response.data);
                    }
            }
        }

        return Promise.reject(err);
    }
);

import {
    ValidationProvider,
    ValidationObserver,
    configure
} from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
import { messages } from 'vee-validate/dist/locale/en.json';
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule] // assign message
    });
});

configure({
    classes: {
        invalid: 'is-invalid'
    }
});

// based on https://github.com/aspnet/AspNetIdentity/blob/master/src/Microsoft.AspNet.Identity.Core/PasswordValidator.cs
// since complex regexs hurt my brain
extend('strong_password', {
    message:
        'Password must be at least 7 characters and contain at least: 1 letter and 1 number',
    validate: value => {
        var letters = new RegExp(/[A-Za-z]/);
        if (!letters.test(value)) {
            return false;
        }

        var numbers = new RegExp(/[0-9]/);
        if (!numbers.test(value)) {
            return false;
        }

        return true;
    }
});
// based on https://github.com/aspnet/AspNetIdentity/blob/master/src/Microsoft.AspNet.Identity.Core/UserValidator.cs
extend('valid_username', {
    message:
        'User name can only contain alphanumeric characters and @, _, -, + or .',
    validate: value => {
        var letters = new RegExp(/^[A-Za-z0-9@_.+-]+$/);
        return letters.test(value);
    }
});

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import { numberFormatter } from './components/Common/Formatters.js';
Vue.filter('number', numberFormatter);

const requireComponent = require.context(
    // The relative path of the components folder
    './components',
    // Whether or not to look in subfolders
    false,
    // The regular expression used to match base component filenames
    /p-[\w.-]+\.(vue|js)$/
);

requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    // Get PascalCase name of component
    const componentName = upperFirst(
        camelCase(
            // Gets the file name regardless of folder depth
            fileName
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
        )
    );

    // Register component globally
    Vue.component(
        componentName,
        // Look for the component options on `.default`, which will
        // exist if the component was exported with `export default`,
        // otherwise fall back to module's root.
        componentConfig.default || componentConfig
    );
});

Vue.mixin({
    created: function() {
        this.SECURITYROLES = {
            DEVELOPER: 'Developer',
            SUPPORT: 'Support',
            ADMIN: 'Admin',
            GMBUYER: 'GM Buyer',
            STOREMANAGER: 'Store Manager',
            BUYBACKUSER:'Buyback User',
            MERCHANDISEMANAGER: 'Merchandise Manager'
        }
    }
})


import VueQuillEditor from 'vue-quill-editor'
Vue.use(VueQuillEditor)
function contentWatchOverride(newVal) {
    if (this.quill) {
        if (newVal && newVal !== this._content) {
            this._content = newVal
            const delta = this.quill.clipboard.convert(newVal)
            this.quill.setContents(delta)
        } else if (!newVal) {
            this.quill.setText('')
        }
    }
}
const Base = Vue.options.components['quill-editor']
const CustomQuillEditor = Base.extend({
    beforeCreate() {
        Vue.set(this.$options.watch, 'content', contentWatchOverride.bind(this))
        Vue.set(this.$options.watch, 'value', contentWatchOverride.bind(this))
    }
})
Vue.component('custom-quill-editor', CustomQuillEditor)

import moment from 'moment';
Vue.prototype.$validDates = [
    'YYYY-MM-DD HH:mm:ss.SSS',
    'YYYY/MM/DD',
    'YYYY.MM.DD',
    'MM-DD-YYYY',
    'MM/DD/YYYY',
    'MM.DD.YYYY',
    moment.ISO_8601
];

//mount app
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');