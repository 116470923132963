<template>
    <span v-if="isAuthorized">
        <slot></slot>
    </span>
    <div v-else-if="showPermissionMessage">
        <div class="row pt-5">
            <div class="col-12 text-center">
                <h2 class="text-thin">You do not have permission to view this page.</h2>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        requireAdmin: Boolean,
        requireRole: Array,
        prismRbsOnly: Boolean,
        showPermissionMessage: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        isAuthorized() {
            if (this.requireAdmin == true) {
                return this.$store.getters.currentUserHasRole('Admin');
            }

            if (this.requireRole) {
                if (this.$store.getters.currentUserHasRole('Admin')) {
                    return true;
                }

                for (let i = 0; i < this.requireRole.length; i++) {
                    if (this.$store.getters.currentUserHasRole(this.requireRole[i])) {
                        return true;
                    }
                }

                return false;
            }

            if (this.prismRbsOnly) {
                return (
                    this.$store.getters.currentUserHasRole('Support') || this.$store.getters.currentUserHasRole('Developer')
                );
            }

            return true;
        }
    }
};
</script>

<style></style>
