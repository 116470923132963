import axios from 'axios';
import store from '../store/store.js';

export default {
    async getStoreDropdownData(storeName) {
        var url = '/SelectLists/' + storeName;

        if ((typeof store.state[storeName] == 'object' && store.state[storeName].length ) ||
            (typeof store.state[storeName] == 'string' && store.state[storeName]) ) {
            return store.state[storeName];
        }
        else {
            return await axios
                .get(url)
                .then(response => {
                    store.dispatch('UpdateMutation', { name: 'update' + storeName.charAt(0).toUpperCase() + storeName.slice(1), data: response.data });
                    return response.data;
                });
        }
    },

    async getSelectedLocationIdAsync() {
        let selectedLocationId = store.getters.getSelectedLocationId 
            ?? await axios.get('selectlists/singlelocationid').then((resp) => {
                        return resp.data;
                    });
        return  parseInt(selectedLocationId);
    }
}