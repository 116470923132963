<template>
    <b-row>
        <b-col>
            <b-list-group class="h-100">
                <div class="pl-2">{{ label }}</div>
                <draggable
                    v-model="innerValue"
                    class="draggable-component h-100"
                    @input="handleInput"
                    group="optionGroup"
                    draggable=".draggable"
                >
                    <b-list-group-item
                        class="draggable list-item"
                        v-for="(element, index) in innerValue"
                        :key="element.name"
                        :active="element.active"
                    >
                        <b-row>
                            <b-col class="pr-1">
                                <span v-if="numbered">{{ index + 1 }}. </span>
                                {{ element.name }}
                            </b-col>
                            <b-col cols="auto" class="ml-auto pl-0 pr-2">
                                <small v-if="index === 0"
                                    >{{ topMessage }}
                                </small>
                                <small
                                    v-if="
                                        index === innerValue.length - 1 &&
                                            innerValue.length > 1
                                    "
                                    >{{ bottomMessage }}
                                </small>
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                    <div
                        slot="footer"
                        v-if="footer != ''"
                        class="pt-2 text-center"
                    >
                        {{ footer }}
                    </div>
                </draggable>
            </b-list-group>
        </b-col>
        <!-- AdditionalOptions can be used to provide a list of options that can be dragged into the main list-->
        <b-col v-if="innerAdditionalOptions != null">
            <b-list-group class="h-100">
                <div class="pl-2">
                    {{ additionalOptionsLabel }}
                </div>
                <draggable
                    v-model="innerAdditionalOptions"
                    class="draggable-options-component h-100"
                    group="optionGroup"
                    draggable=".draggable"
                >
                    <b-list-group-item
                        class="draggable options-list"
                        v-for="element in innerAdditionalOptions"
                        :key="element.name + 'additionalOptions'"
                        :active="element.active"
                    >
                        <b-row>
                            <b-col class="pr-1">
                                {{ element.name }}
                            </b-col>
                        </b-row>
                    </b-list-group-item>
                </draggable>
            </b-list-group>
        </b-col>
    </b-row>
</template>

<script>
import draggable from 'vuedraggable';
import baseInputs from '@/components/mixins/BaseInputs';

export default {
    name: 'p-draggable',
    mixins: [baseInputs],
    components: {
        draggable
    },
    props: {
        value: [Array],
        topMessage: {
            type: String,
            default: ''
        },
        bottomMessage: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        footer: {
            type: String,
            default: ''
        },
        additionalOptionsLabel: {
            type: String,
            default: ''
        },
        numbered: {
            type: Boolean,
            default: false
        },
        additionalOptions: {
            type: Array,
            default: () => null
        }
    },
    data() {
        return {
            innerValue: [],
            innerAdditionalOptions: []
        };
    },
    created: function() {
        this.innerValue = this.value;
        this.innerAdditionalOptions = this.additionalOptions;
    },
    watch: {
        value: {
            handler: function(newVal) {
                this.innerValue = newVal;
            }
        },
        additionalOptions: {
            handler: function(newVal) {
                this.innerAdditionalOptions = newVal;
            }
        }
    }
};
</script>
<style scoped lang="scss">
@import '@/styles/app/common/variables.scss';
.draggable {
    cursor: grab;
}
.draggable-component {
    box-shadow: 0 0 0 1px $gray-light;
    min-height: 116px;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
}
.draggable-options-component {
    min-height: 116px;
    opacity: .80;
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
}
.options-list:not(.active)  {
    background:  $gray-lighter;
}
.list-item {
    border: 0px;
    box-shadow: 0 0 0 1px $gray-light;
    border-right: 0px;
    border-left: 0px;
}
div.sortable-ghost {
    border: 0px;
    color: #555;
    background-color: $gray-light;
}
</style>
