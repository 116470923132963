

export function phoneFormatter(phone) {
    if (phone == null) {
        return null;
    }
    if (phone.extension == null || phone.extension == 'null') {
        return phone.number;
    }

    return phone.number + ' ' + phone.extension
}
export function dateFormatter(value) {
    if (value == null || value === '') {
        return null;
    }

    return (new Date(value)).toLocaleString('en', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: window.timeZone
    });

}

export function dateFormatterYear(value) {
    if (value == null || value === '') {
        return null;
    }

    return (new Date(value)).toLocaleString('en', {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        timeZone: window.timeZone
    })
}

export function datetimeToLocalTimeOnlyFormatter(value) {
    if (value == null || value === '') {
        return null;
    }
    return (new Date(value)).toLocaleTimeString('en', {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: window.timeZone
    });
}

export function datetimeToLocalTimeFormatter(value) {
    if (value == null || value === '') {
        return null;
    }
    return (new Date(value)).toLocaleString('en', {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone: window.timeZone
    });
}

export function numberFormatter(value) {
    if (value == null) {
        return  null;
    }
    return value.toLocaleString('en', { useGrouping: true, maximumFractionDigits: 2, minimumFractionDigits: 2 });
}

export function roundingFormatter(value, maxDecimalDigits) {
    if (value == null) {
        return null;
    }
    let roundingMultiplier = Math.pow(10, maxDecimalDigits);
    return Math.round(value * roundingMultiplier) / roundingMultiplier
}

export function integerFormatter(value, nullValue) {
    if (value == null) {
        return  nullValue ? nullValue : null;
    }
    return value.toLocaleString('en', { useGrouping: true, maximumFractionDigits: 0 });
}

export function currencyFormatter(value, nullValue) {
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    if(value == null) {
        return nullValue ? nullValue : null;
    }

    return formatter.format(value);
}

export function searchPlaceHolderFormatter(options,searchBy) {
    let allScans = ['SKU','ISBN','Barcode','Scan Code'];
    let scanFlag;
    let optionFields;
    if(searchBy && searchBy != 'All'){
        scanFlag = allScans.some(x=>x.toLowerCase()==searchBy.toLowerCase());
    }else{
        scanFlag = options.some(x=>allScans.some(y=>y.toLowerCase()==x.toLowerCase()));
        optionFields = options[0] === 'All' ? options?.slice(1) : options
    }
    return `${scanFlag?'Scan/Search by ':'Search by '}${searchBy && searchBy != 'All'?searchBy:listToStringFormatter(optionFields, 'or')}`
}

export function listToStringFormatter(propertyList, conjuction) {
    //converts ['jane', 'bob', 'sue'] to 'jane, bob and sue'.  only uses comas if more than 2 elements

    //keep the comma before the and/or to match existing format
    conjuction = propertyList?.length > 2 ? ', ' + conjuction : ' ' + conjuction

    return propertyList
        ?.join(', ')
        .replace(/,(?!.*,)/gmi, `${conjuction}`);

}

export function booleanFormatter(value) {
    if (value == null) {
        return null;
    }

    return value == 0 ? 'No' : 'Yes';
}

export function isActiveFormatter(value) {
    if (value == null) {
        return null;
    }

    return value ? 'Active' : 'Inactive';
}

export function stringHasValueFormatter(value) {
    return value ? 'Yes' : 'No';
}


export function percentFormatter(value, precision) {
    if (value == null) {
        return null;
    }
    if(!precision){
        precision = 2;
    }
    value = value / 100;

    return value
        .toLocaleString('en-US', { style: 'percent', minimumFractionDigits: precision, maximumFractionDigits: precision })
        .replace("%", " %");
}

export function bookVariantFormatter(value) {
    if (value !== null && typeof value === 'object') {
        return null;
    }
    switch (value) {
        case 1:
            return "New Text";
        case 2:
            return "Used Text";
        case 3:
            return "Digital";
        case 4:
            return "Trade";
        default:
            return "GM";
    }
}

export function productTypeFormatter(value) {
    if (value !== null && typeof value === 'object') {
        return null;
    }
    switch (value) {
        case 1:
            return "GM";
        case 2:
            return "Book";
        case 3:
            return "Package";
        case 4:
            return "Non-Merch"
    }
}

export function dccNumberFormatter(value) {
    //number to a 2 digit string
    return value.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
}

export function addressFormatter(value, store) {
    if (value == null) {
        return '';
    }
    return [
        value.street,
        value.city,
        (store.getters.getStateById(value.stateId)?.text ?? '') + ' ' + (value.postalCode ?? '')
    ]
        .filter(x => x != null && x != ' ' && x != '')
        .map(x => x.trimEnd())
        .join(', ');
}

//searches for text, provides some formatting so matches do
//not need to be exact.
export function textIncludes(fullText, textToFind) {
    let splitSearchText = textToFind.split(" ");
    let matchIndex = fullText
        .toLowerCase()
        .indexOf(splitSearchText[0].toLowerCase())
    //also search with non alpha numeric characters removed
    //so 010203 hits  01-02-03 and 'or' hits O'Reilly

    if (matchIndex < 0) {
        matchIndex = fullText
            .toLowerCase()
            .replace(/[^0-9a-z]/gi, "")
            .indexOf(splitSearchText[0].toLowerCase())
    }

    if (splitSearchText.length === 1) { return matchIndex > -1 }

    let remainingFullText = fullText.substring(matchIndex + splitSearchText[0].length);
    splitSearchText.shift();
    //allow space to act similar to a * wildcard
    return matchIndex > -1 && textIncludes(remainingFullText, splitSearchText.join(" "));
}

export function dashIfNullFormatter(value) {
    if (value == null || value === '') {
        return "-";
    }
    return value;
}