<template>
  <!--  Using :value="value" was not loading with selected options checked,
      so I'm using v-model="selection" to properly load with selected values
-->
  <b-form-group :label="labelText">
    <b-form-checkbox-group
      :name="name"
      v-model="selection"
      v-bind="$attrs"
      @input="handleInput($event)"
    ></b-form-checkbox-group>
  </b-form-group>
</template>

<script>

import baseInputs from '@/components/mixins/BaseInputs';

export default {
  name: "p-checkbox-group",
  mixins: [baseInputs],
  props: {
    value: [Array]
  },
  data() {
    return {
      selection: []
    };
  },
  watch: {
    value: {
      handler: function(newVal) {
        //keep selection up to date in case this
        //property was updated outside of this component
        this.selection = newVal;
        this.$emit('change', this.selection);
      }
    }
  },
  mounted: function() {
      this.selection = this.value;
  }
};
</script>
