<template>
    <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form v-bind="$attrs" @submit.prevent="handleSubmit(onSubmit)">
            <slot></slot>
        </form>
    </ValidationObserver>
</template>

<script>
export default {
    inheritAttrs: false,
    name: 'p-form',
    methods: {
        validate() {
            return this.$refs.observer.validate();
        },
        onSubmit() {
            this.$emit('submit');
        },
        isFieldInvalid(fieldName){
           let field = this.$refs.observer.fields[fieldName];
           if(field)
           {
               return field.invalid;
           }
           return null;
        },
    }
};
</script>
