<template>

    <div v-if="data != null && data.length > 0"
        >
        <div ref="treeContainer" :class="{'collapsed-height' : addCollapsedClass,'expanded-height' : addExpandedClass}">
        <!-- https://github.com/zdy1988/vue-jstree -->
            <b-form-group class="pb-0 mb-1" v-if="showSearchFilter">
                <p-input name="searchInput" :placeholder="filterPlaceHolder" size="sm" class="mr-2" v-model="userFilter"  type="search"> </p-input>
            </b-form-group>
            <v-jstree
                v-bind="$attrs"
                :data="data"
                v-on="$listeners"
                show-checkbox
                allow-batch
                whole-row
                multiple
                size="small"
                @item-click="itemClick"
                @item-toggle="toggle"
                ref="tree"
            >
            </v-jstree>
        </div>
        <div class="text-center mt-1">
            <b-button
                variant="light"
                size="sm"
                class="py-0"
                v-if="addCollapsedClass && tallEnoughForExpansion"
                @click="expanded = true"
                >Show More
                <b-icon-chevron-down></b-icon-chevron-down
            ></b-button>
            <b-button
                variant="light"
                size="sm"
                class="py-0"
                v-if="addExpandedClass"
                @click="expanded = false"
                >Show Less
                <b-icon-chevron-up></b-icon-chevron-up
            ></b-button>
        </div>
    </div>
</template>

<script>
import $ from '@/components/Common/wrapper.js';
import VJstree from 'vue-jstree';
import {textIncludes} from "@/components/Common/Formatters.js";

export default {
    name: 'p-tree',
    components: {
        VJstree
    },
    props: {
        data: {
            type: Array,
            default: () => []
        },
        //set to false to remove expandable functionality
        expandable: {
            type: Boolean,
            default: true
        },
        includeSearchFilter: {
            type: Boolean,
            default: false
        },
        //pass a search placeholder to indicate what the user can filter by.
        //It currently only filters the top level, so include the name
        //of what is in the top level
        filterPlaceHolder: {
            type: String,
            default: "Filter"
        }
    },
    data() {
        return {
            expanded:false,
            tallEnoughForExpansion:false,
            userFilter: ''
        }
    },
    computed:{
        showSearchFilter(){
            return this.includeSearchFilter && this.data.length > 1;
        },
        addCollapsedClass(){
            return this.expandable && !this.expanded;
        },
        addExpandedClass(){
            return this.expandable && this.expanded;
        },
    },
    watch: {
        userFilter() {
            //hiding elements instead of removing them from data
            //because that messes up tree selection tracking.
            this.$refs.tree.$children.forEach(x => {
                if(!textIncludes(x.model.text, this.userFilter)) {
                    x.$el.style.display = 'none';
                }
                else{
                    x.$el.style.display = '';
                }
            });
        },

        },
    methods: {
        itemClick(/*node, item, e*/) {
            this.$emit('selection-change', this.data);
            this.$nextTick(() => {
                $('.tree-selected i.tree-checkbox').addClass('fa fa-check')
                $('.tree-anchor:not(.tree-selected) i.tree-checkbox').removeClass('fa fa-check')
            });
        },
        //triggerd on expand or collapse
        toggle() {
            //after the expansion is rendered see if there is overflow
            setTimeout(() => {
                let container = this.$refs.treeContainer;
                this.tallEnoughForExpansion = container.scrollHeight > container.clientHeight;
            }, 300)
        }
    }
};
</script>


<style scoped lang="scss">
@import "@/styles/app/common/variables.scss";
/deep/ .tree-selected i.tree-icon.tree-checkbox.fa.fa-check{
    //vue-jstree checkbox was created using a background-image, hiding that and using
    //css to build checkboxes that resemeble our p-checkbox
    background-image: none;
    background-color: $primary;
    color: white;
    border: 2px solid $primary;
    border-top: 0px;
    font-size: .6em;
}

/deep/ .tree-anchor i.tree-icon.tree-checkbox{
    background-image: none;
    color: white;
    border: 1px solid $input-default-border-color;
    height: 16px;
    width: 16px
}
.collapsed-height {
    max-height: 180px;
    overflow:auto;
}

.expanded-height {
    max-height: 450px;
    overflow:auto;
}

fieldset {
    border-bottom: none;
}
</style>