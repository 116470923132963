
const CurrentUserModule = {
    state: {
        currentUser: null
    },
    getters: {
        getCurrentUser(state) {
            return state.currentUser;
        },
        getCurrentUserId(state){
            return state.currentUser.profile.sub.split(
                ':'
            )[1];
        },
        getCurrentUserRoles(state){
            return state.currentUser ? state.currentUser.profile.role : [];
        },
        currentUserHasRole: (state) => (role) => {
            if(state.currentUser && state.currentUser.profile.role){
                if(Array.isArray(state.currentUser.profile.role))
                {
                    return state.currentUser.profile.role.includes(role);
                }else{
                    return role === state.currentUser.profile.role;
                }
            }

            return false;
        }
    },
    mutations: {
        setCurrentUser(state, value){
            state.currentUser = value;
        }
    }
}

export default CurrentUserModule