<template>
    <ValidationProvider
        tag="div"
        :rules="combinedRules"
        :name="label || name"
        :vid="vid"
        :mode="mode"
        v-slot="{ classes, errors }"
    >
        <b-form-group
            :label-for="name || labelText"
            :label="labelText"
            label-class="text-capitalize"
            :class="{
                'mb-0': compactFormat,
                'compact-container': compactFormat && ! narrowInput,
                'narrow-container': narrowInput,
                'push-right': pushRight
            }"
        >
            <template
                v-for="(_, name) in $scopedSlots"
                :slot="name"
                slot-scope="slotData"
            >
                <slot :name="name" v-bind="slotData" />
            </template>
            <b-input-group>
                <currency-input
                    v-bind="$attrs"
                    ref="input"
                    :name="name"
                    :currency="currency"
                    :precision="precision"
                    @input="handleInput($event)"
                    v-on="$listeners"
                    :disabled="$attrs.disabled || textOnly"
                    :class="{
                        ...classes,
                        'form-control': true,
                        'form-control-plaintext': textOnly,
                        'pr-2': compactFormat,
                        'compact-control': compactFormat,
                        'align-right': alignRight
                    }"
                    :value="value"
                    @focus.native="$event.target.select()"
                ></currency-input>
                <template v-slot:append v-if="infoMessage">
                    <b-input-group-text class="px-1">
                        <b-icon-info-circle
                            v-b-tooltip.hover
                            :title="infoMessage"
                        ></b-icon-info-circle>
                    </b-input-group-text>
                </template>
                <template
                    v-for="(_, name) in $scopedSlots"
                    :slot="name"
                    slot-scope="slotData"
                >
                    <slot :name="name" v-bind="slotData" />
                </template>
            </b-input-group>

            <span>{{ errors[0] }}</span>
        </b-form-group>
    </ValidationProvider>
</template>
<script>
import debounce from 'lodash/debounce';
import baseInputs from '@/components/mixins/BaseInputs';
import validatedInput from '@/components/mixins/ValidatedInput';
export default {
    name: 'p-number',
    mixins: [baseInputs, validatedInput],
    data: function() {
        return {
            isInputActive: false,
            //configure for our standard types here to avoid having to duplicate settings on each use
            inputTypes: {
                money: {
                    precision: undefined, //use default (.00 for US)
                    symbol: undefined //use default ($ prefix for US)
                },
                percent: {
                    //keep trailing zeros to keep alignment with other percentages for easy comparison
                    precision: { min: 2, max: 2 },
                    symbol: { suffix: ' %' }
                },
                percentHighPrecision: {
                    //keep trailing zeros to keep alignment with other percentages for easy comparison
                    precision: { min: 3, max: 3 },
                    symbol: { suffix: ' %' }
                },
                decimal: {
                    precision: { min: 0, max: 2 },
                    symbol: {}
                },
                integer: {
                    precision: { min: 0, max: 0 },
                    symbol: {}
                }
            }
        };
    },
    props: {
        value: [Number, String],
        debounceWait: {
            type: Number,
            default: 0
        },
        inputType: {
            type: String,
            default: 'decimal'
        },
        addMoneyRules: {
            type: Boolean,
            default: null
        },
        compactFormat: {
            type: Boolean,
            default: false
        },
        narrowInput: {
            type: Boolean,
            default: false
        },
        textOnly: {
            type: Boolean,
            default: false
        },
        leftAlign: {
            type: Boolean,
            default: false
        },
        //push entire input right
        pushRight: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        currency() {
            return this.inputTypes[this.inputType].symbol;
        },
        precision() {
            return this.inputTypes[this.inputType].precision;
        },
        alignRight() {
            //default is to align right. So in order to align left we return false to remove the align right css.
            return !this.leftAlign;
        },
        combinedRules() {
            //if there are validation rules we would expect an input type to always have, we can add them here
            //default to add money rules when in money mode.  It can be turned of
            //by explicitly setting addMoneyRules to false
            if (
                this.addMoneyRules === true ||
                (this.addMoneyRules === null && this.inputType === 'money')
            ) {
                //the db should be able to store 18,4.  However, the user would
                //see some weird behavior when going above 16, 3
                //(rounding small decimal places and truncating decimal places when there were 14 digits to left of decimal).
                // We don't have a need to support this at this time.  We'll likely go to 2 decimals in money anyway.
                if (this.rules.length) {
                    return this.rules + '|p-decimal:16,3';
                }
                return 'p-decimal:16,3';
            }
            if (this.inputType === 'integer') {
                if (this.rules.length) {
                    return this.rules + '|p-integer';
                }
                return 'p-integer';
            }
            return this.rules;
        }
    },
    created() {
        if (this.debounceWait > 0) {
            this.handleInput = debounce(this.handleInput, this.debounceWait);
        }
    },
    methods: {
        focus: function() {
            this.$refs.input.$el.focus();
        }
    }
};
</script>
<style scoped lang="scss">
/deep/ input.align-right {
    text-align: right;
}
/deep/ .form-control-plaintext {
    border: none;
    padding: 0 10px;
    margin: 0;
    background: none;
}
/deep/ .compact-control {
    padding-bottom: 5px;
    padding-top: 8px;
    padding-left: 2px;
    height: 1.5rem;
    width: 85px;
}

/deep/ .compact-container {
    width: 88px;
}

/deep/ .narrow-container {
    width: 55px;
}

/deep/ .push-right {
    margin-left: auto;
}

</style>
