<template>
    <div >
        <Multiselect
            v-bind="$attrs"
            :name="name"
            v-on="$listeners"
            v-model="innerValue"
            @input="handleInput(innerValue)"
            track-by="value"
            label="text"
            :options="options"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :hide-selected="false"
            :preserve-search="false"
        >
        </Multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import baseInputs from '@/components/mixins/BaseInputs';

export default {
    name: 'p-multiselect',
    mixins: [baseInputs],
    components: {
        Multiselect
    },
    props: {
        value: [Array],
        options: Array
    },
    data() {
        return {
            innerValue: []
        };
    },
    watch: {
        value: {
            handler: function(newVal) {
                this.innerValue = newVal;
            }
        }
    }
};
</script>


<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped lang="scss">
@import '@/styles/app/common/variables.scss';
/deep/ span.multiselect__tag {
    background-color: $primary;
}
/deep/ .multiselect__option--highlight {
    background-color: $primary;
}
/deep/ .multiselect__option--highlight.multiselect__option--selected {
    background-color: $dark;
}
/deep/ .multiselect__option--highlight.multiselect__option--selected:after {
    background-color: $dark;
}
/deep/ .multiselect__option--highlight:after {
    background-color: $primary;
}
/deep/ .multiselect__tag-icon:after {
    color: $secondary;
}
/deep/ .multiselect__tag-icon:hover {
    background-color: $primary-light;
}
/deep/ div.multiselect__tags {
    border-radius: 0;
    padding-top: 3px;
    border-color: $gray;
}

/deep/ .multiselect__content-wrapper {
    border-radius: 0;
    box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px;
}
/deep/ .multiselect__placeholder {
    color: $input-placeholder-color;
}
</style>
