<template>
    <div>
        <b-row>
            <b-col>
                <b-radio
                    :value="0"
                    v-model="dateRangeOptions.dateOption"
                    @change="eventSetDates(0)"
                >
                    Today
                </b-radio>
            </b-col>
        </b-row>
        <b-row class="pt-1">
            <b-col>
                <b-radio
                    :value="1"
                    v-model="dateRangeOptions.dateOption"
                    @change="eventSetDates(1)"
                >
                    Yesterday
                </b-radio>
            </b-col>
        </b-row>
        <b-row class="pt-1">
            <b-col>
                <b-radio
                    :value="2"
                    v-model="dateRangeOptions.dateOption"
                    @change="eventSetDates(2)"
                >
                    Custom
                </b-radio>
            </b-col>
        </b-row>
        <b-row class="pt-2">
            <b-col>
                <p-datepicker
                    label="Start Date"
                    vid="startDate"
                    v-model="dateRangeOptions.startDate"
                    :disabled="disabled"
                    rules="required"
                />
            </b-col>
            <b-col>
                <p-datepicker
                    label="End Date"
                    vid="endDate"
                    v-model="dateRangeOptions.endDate"
                    rules="required|dateGreaterOrEqual:@startDate"
                    :disabled="disabled"
                    is-end-date
                />
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    name: 'DateRangeTemplate',
    components: {},
    props: {
        dateRangeOptions: {
            dateOption: 0,
            startDate: null,
            endDate: null,
        }
    },
    data() {
        return {
            disabled: true
        };
    },
    methods: {
        async loadData() {
            let today = this.getToday();

            this.dateRangeOptions.endDate = today;
            this.dateRangeOptions.startDate = today;
            this.dateRangeOptions.dateOption = 0;
        },
        getToday(){
            let today = new Date();
            return today;
        },
        getYesterday(){
            let yesterday = (d => new Date(d.setDate(d.getDate() - 1)))(
                new Date()
            );

            return yesterday;
        },
        eventSetDates(value) {
            let today = this.getToday();
            let yesterday = this.getYesterday();

            if (value == 0) {
                this.disabled = true;
                this.dateRangeOptions.startDate = today;
                this.dateRangeOptions.endDate = today;
            } else if (value == 1) {
                this.disabled = true;
                this.dateRangeOptions.startDate = yesterday;
                this.dateRangeOptions.endDate = yesterday;
            } else {
                this.disabled = false;
                this.dateRangeOptions.startDate = yesterday;
                this.dateRangeOptions.endDate = today;
            }
        }
    },
    mounted: async function() {
        await this.loadData();
    },
};
</script>
