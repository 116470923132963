<template>
  <ValidationProvider tag='div' :rules='rules' :name='label || name' :vid='vid' :mode='mode' v-slot='{ classes, errors }'>
    <b-form-group :label-for='name || labelText' :label='labelText' :class='{ ...classes, "date-container-compact": compactFormat }'>
      <div class='date-container'>
        <datepicker
            v-bind='$attrs'
            :name='name'
            ref='datePicker'
            :value='computedValue'
            format='MM/dd/yyyy'
            @input='handleInput2($event)'
            :available-dates='availableDates'
            :popover='visibility'
            :class='{ ...classes,"compact-control" : compactFormat}'
            :timezone='timezone'
            :model-config='modelConfig'
        >
            <b-row slot-scope='{ inputValue, inputEvents }'>
                <b-col>
                <b-input-group>
                    <input
                    :class='{ ...classes, "form-control" : true}'
                    :debounce='1000'
                    :value='inputValue'
                    v-on='inputEvents'
                    :disabled='disabled' />
                    <b-button v-if='!disabled'
                        class='px-0'
                        size='md'
                        :disabled='disabled'
                        @click='clearInput'>
                        <b-icon-x ></b-icon-x>
                    </b-button>
                    <b-input-group-text class="px-0" v-if='infoMessage'>
                        <b-icon-info-circle
                            v-b-tooltip.hover
                            :title="infoMessage"
                        ></b-icon-info-circle>
                    </b-input-group-text>
                </b-input-group>
                </b-col>
            </b-row>
        </datepicker>
        <span>{{ errors[0] }}</span>
        </div>
    </b-form-group>
  </ValidationProvider>
</template>
<script>
import baseInputs from '@/components/mixins/BaseInputs';
import validatedInput from '@/components/mixins/ValidatedInput';
import Datepicker from 'v-calendar/lib/components/date-picker.umd';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import { isMoment } from 'moment';
export default {
  data(){
      return {
           inputValue: null,
           timezone: '',
           modelConfig: {
               timeAdjust: this.isEndDate ? '23:59:59.997' : '00:00:00.000'
           },
           updateValue: true,
           valueCleared: false
      }
  },
  name: 'p-datepicker',
  mixins: [baseInputs, validatedInput],
  watch: {
      value: {
      handler: function() {
        //catch any value changes made outside this component
        if(this.value)
        {
            //only take the value when modified by the input or datepicker
            //run any v-model changes through updateInput to apply timezone
            if(this.valueCleared)
            {
                this.valueCleared = false;
                this.updateInput(this.value);
                return;
            }
            if(this.updateValue)
            {
                this.inputValue = this.value;
                this.updateValue = false;
            }else{
                this.updateValue = true;
                this.updateInput(this.value);
            }
        }
        else{
            this.inputValue = this.value;
        }

        this.$emit('end-of-selected-day',
            this.inputValue == null ? null :  new Date(new Date(this.inputValue).getTime() + 86400000 - 3 /* one day minus 3ms */ ));
      },
    }
  },
  computed: {
      computedValue: function() {
         return this.inputValue ?? '';
      },
      visibility: function() {
          return { visibility: this.disabled ? 'hidden' : 'click' };
      },
      availableDates: function() {
          return { start: this.startDate, end: new Date(9999, 0, 1) };
      }
  },
  props: {
    value: [String, Date, Object],
    disabled: {
        type: Boolean,
        default: false
    },
    compactFormat:{
        type: Boolean,
        default: false
    },
    startDate: {
        type: Date,
        default: function() { return new Date(1900, 0, 1) }
    },
    isEndDate: {
        type: Boolean,
        default: false
    }
  },
  methods: {
      handleInput2(event)
      {
          this.updateValue = !this.clearInput;
          this.handleInput(event);
      },
      updateInput(value)
      {
        let valueDate = null;
        if (typeof value === 'string') {
            valueDate = new Date(value);
        }
        else if (isMoment(value)) {
            valueDate = value.toDate();
        } else {
            valueDate = value;
        }

        let valueWithTime = !value || value == '' ? '' : this.isEndDate ? new Date(valueDate.setHours(23, 59, 59, 0)) : new Date(valueDate.setHours(0, 0, 0, 0));
        if(valueWithTime != '' && this.timezone)
        {
            //apply timzeone to date
            valueWithTime = new Date(valueWithTime.toLocaleString("en-US", {timeZone: this.timezone}));
            //get value's timestamp
            let valueTimeStamp = valueDate.getTime();
            //diff the value's timestamp against the value with time set above
            let diff = valueTimeStamp - valueWithTime.getTime();
            //make a new date including the offset
            //valueWithTime will represent current time + the hour diff
            valueWithTime = new Date(valueTimeStamp + diff);
            //Add 997 milliseconds if end date
            valueWithTime = this.isEndDate ? new Date(valueWithTime.setMilliseconds(997)) : valueWithTime;
        }
        this.inputValue = valueWithTime == '' ? null : valueWithTime;
        this.updateValue = true;
        this.handleInput(this.inputValue);
      },
      clearInput() {
          this.valueCleared = true;
          this.handleInput(null);
      }
  },
  async beforeCreate() {
    this.timezone = await selectListOptionsDataContext.getStoreDropdownData('storeTimeZone');
    this.updateInput(this.value);

    //We have a timing issue with the navigation guard isDirty. In some cases, the updateInput
    //change of the value happens after the form is marked clean (marking it dirty on load).
    //This event allows to clean the form after the datepicker as been initialized.

    //example usage on a p-datepicker:  @date-picker-initialized="e => {if(item.courseRequestBookId){dataLoaded()}}"
    //dataLoaded is put inside the if so it doesn't clean the form when a new item is added to the table (we want to be dirty in that case).
    //Not needed in places we can't add a new date picker through the ui.
    this.$emit('date-picker-initialized');
  },
  components: {
    Datepicker
  }
};
</script>
<style scoped lang='scss'>
@import '@/styles/app/common/variables.scss';
/deep/  .form-control[readonly] {
    background-color: white;
}
/deep/  .form-control[disabled] {
    background-color: #edf1f2;
}
/deep/  .date-container {
    min-width:90px;  /*should be big enough to at least show the day/month */
}
/deep/ input::-ms-clear {
  display: none;
}
/deep/  button {
    background-color: white;
    border-top:1px solid $gray;
    border-bottom:1px solid $gray;
    border-right:1px solid $gray;
    border-left:0px;;
    height:35px;
    border-radius: 0px;
}
/deep/ .compact-control input{
    padding-bottom: 5px;
    padding-top: 8px;
    margin-top:1px;
    padding-left: 2px;
    height: 1.5rem;
}
/deep/  .compact-control button{
    height: 1.5rem;
    padding-top: 3px;
    margin-top:1px;
}
</style>