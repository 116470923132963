var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"tag":"div","rules":_vm.combinedRules,"name":_vm.label || _vm.name,"vid":_vm.vid,"mode":_vm.mode},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('b-form-group',{class:{
            'mb-0': _vm.compactFormat,
            'compact-container': _vm.compactFormat && ! _vm.narrowInput,
            'narrow-container': _vm.narrowInput,
            'push-right': _vm.pushRight
        },attrs:{"label-for":_vm.name || _vm.labelText,"label":_vm.labelText,"label-class":"text-capitalize"},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},[_c('b-input-group',{scopedSlots:_vm._u([(_vm.infoMessage)?{key:"append",fn:function(){return [_c('b-input-group-text',{staticClass:"px-1"},[_c('b-icon-info-circle',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":_vm.infoMessage}})],1)]},proxy:true}:null,_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true)},[_c('currency-input',_vm._g(_vm._b({ref:"input",class:Object.assign({}, classes,
                    {'form-control': true,
                    'form-control-plaintext': _vm.textOnly,
                    'pr-2': _vm.compactFormat,
                    'compact-control': _vm.compactFormat,
                    'align-right': _vm.alignRight}),attrs:{"name":_vm.name,"currency":_vm.currency,"precision":_vm.precision,"disabled":_vm.$attrs.disabled || _vm.textOnly,"value":_vm.value},on:{"input":function($event){return _vm.handleInput($event)}},nativeOn:{"focus":function($event){return $event.target.select()}}},'currency-input',_vm.$attrs,false),_vm.$listeners))],1),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }