<template>
    <b-overlay
        :show="isBusy"
        rounded
        opacity="0.5"
        spinner-small
        spinner-variant="primary"
        class="d-inline-block"
    >
        <b-button
            v-bind="$attrs"
            :class="{ 'px-0 py-0 no-border': noBorder }"
            ref="button"
            @click="$emit('click', $event)"
        >
            <slot></slot>
        </b-button>
    </b-overlay>
</template>

<script>
export default {
    props: {
        isBusy: {
            type: Boolean,
            default: false
        },
        finishedMessage: {
            type: String,
            default: ''
        },
        noBorder: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        isBusy: {
            handler: function(newVal) {
                if (!newVal && this.finishedMessage != '') {
                    this.$toasted.global
                        .app_success(this.finishedMessage)
                        .goAway(2000);
                }
            }
        }
    }
};
</script>
<style>
.no-border {
    border: 0px;
}
</style>
