<template>
    <ContentWrapper>
        <p-form>
            <slot></slot>

            <p-checkbox
                v-if="sameAsAddress"
                @input="syncSameAsAddress"
                v-model="value.useSameAs"
                :label="`Same as ${sameAsAddressName}`"
                :disabled="disabled"
            >
            </p-checkbox>
             <slot name="above-slot"></slot>
            <b-row v-if="showDesc">
                <b-col :md="fullWidth ? 12 : 8" :lg="fullWidth ? 12 : 6">
                    <p-input
                        label="Desc"
                        :debounce-wait="debounceWait"
                        :disabled="value.useSameAs || disabled"
                        :value="value.description"
                        :rules="getValidationRules('max:100')"
                        @change="update('description', $event)"
                    />
                </b-col>
            </b-row>
            <b-row v-if="value.firstName !== undefined">
                <b-col :md="fullWidth ? 12 : 8" :lg="fullWidth ? 12 : 6">
                    <p-input
                        label="First Name"
                        :debounce-wait="debounceWait"
                        :disabled="value.useSameAs || disabled"
                        :value="value.firstName"
                        :rules="getValidationRules('required|max:50')"
                        mode="lazy"
                        @change="update('firstName', $event)"
                    />
                </b-col>
            </b-row>
            <b-row v-if="value.firstName !== undefined">
                <b-col :md="fullWidth ? 12 : 8" :lg="fullWidth ? 12 : 6">
                    <p-input
                        label="Last Name"
                        :debounce-wait="debounceWait"
                        :disabled="value.useSameAs || disabled"
                        :value="value.lastName"
                        :rules="getValidationRules('required|max:50')"
                        mode="lazy"
                        @change="update('lastName', $event)"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col :md="fullWidth ? 12 : 8" :lg="fullWidth ? 12 : 6">
                    <p-text-area
                        label="Address"
                        :debounce-wait="debounceWait"
                        :disabled="value.useSameAs || disabled"
                        :value="value.street"
                        :rules="getValidationRules('max:255')"
                        @change="update('street', $event)"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col :md="fullWidth ? 12 : 8" :lg="fullWidth ? 12 : 6">
                    <p-input
                        label="City"
                        :debounce-wait="debounceWait"
                        :disabled="value.useSameAs || disabled"
                        :value="value.city"
                        :rules="getValidationRules('max:50')"
                        @change="update('city', $event)"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col :md="fullWidth ? 6 : 4" :lg="fullWidth ? 6 : 3">
                    <!-- State validation is unique because the country is saved through
                     the state.  If they want to save a country, a state must be selected.

                     Switched mode to lazy to avoid this behavior:
                     -Customers->Add->select a state from the drop down.
                     Invalidates the field.
                     Able to save anyway, but the validation shouldn’t be triggered.
                     If you select a different state after the initial the error clears.
                     -->

                    <!-- listening for @input here instead of change, because that is what is
                     triggered if state is deselected due to the country being changed  -->
                    <p-select
                        dataType="states"
                        :debounce-wait="debounceWait"
                        :disabled="value.useSameAs || disabled"
                        :value="value.stateId"
                        mode="lazy"
                        @input="update('stateId', $event)"
                        label="State/Province"
                        :filter="value.countryId"
                        add-empty-option
                        :rules="stateValidation"
                    />
                </b-col>
                <b-col :md="fullWidth ? 6 : 4" :lg="fullWidth ? 6 : 3">
                    <p-input
                        label="Postal Code"
                        :debounce-wait="debounceWait"
                        :disabled="value.useSameAs || disabled"
                        :value="value.postalCode"
                        :rules="getValidationRules('max:20')"
                        @change="update('postalCode', $event)"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col :md="fullWidth ? 12 : 8" :lg="fullWidth ? 12 : 6">
                    <p-select
                        dataType="countries"
                        :debounce-wait="debounceWait"
                        :disabled="value.useSameAs || disabled"
                        label="Country"
                        add-empty-option
                        :value="value.countryId"
                        @change="update('countryId', $event)"
                    />
                </b-col>
            </b-row>
        </p-form>
    </ContentWrapper>
</template>

<script>
import Addresses from '@/components/mixins/Addresses.js';

export default {
    name: 'p-address',
    mixins: [Addresses],
    props: {
        value: [Object],
        showDesc: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        requireFullAddress: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            debounceWait: 0
        };
    },
    computed: {
        applyRequiredValidation() {
            if (!this.requireFullAddress) {
                return false;
            }
            //only require fields if object is not empty
            return Object.keys(this.value).some(
                x => this.value[x] !== null && this.value[x] != ''
            );
        },
        stateValidation() {
            //we don't save the country directly (it is known from the state).  So if they want to
            //save a country they need to select a state.
            return this.value.countryId != null ? 'required' : '';
        }
    },

    methods: {
        getValidationRules(inputSpecificRules) {
            return this.applyRequiredValidation
                ? `${inputSpecificRules}|required`
                : inputSpecificRules;
        }
    }
};
</script>
