<template>
    <div>
        <b-col
        cols="auto"
        class="ml-auto mt-2 mb-1 d-inline-block"
        v-if="selectionActions.length > 1"
        >
            <b-dropdown text="Actions" variant="outline-primary" class="">
                <b-dropdown-item
                    v-for="selectionAction in selectionActions"
                    :key="selectionAction.label"
                    @click="actionClicked(selectionAction.action)"
                    :disabled="selectionAction.disabled"
                    v-show="
                        !selectionAction.hidden && (selectedRowCount !== 0 || selectionAction.noSelectionRequired)
                    "
                    >{{ selectionAction.label }}</b-dropdown-item
                >
                <b-dropdown-item disabled v-show="selectedRowCount === 0 && !selectionActions.some(x => !x.hidden && x.noSelectionRequired) ">
                    Please select one or more rows
                </b-dropdown-item>
            </b-dropdown>
        </b-col>
        <b-col
                cols="auto"
                class="ml-auto mb-1 pl-2 d-inline-block"
                v-if="selectionActions.length === 1"
        >
            <p-button
                :variant="
                    selectionActions[0].variant || 'outline-primary'
                "
                :disabled="
                    selectedRowCount === 0 ||
                        selectionActions[0].disabled
                "
                v-if="!selectionActions[0].hidden"
                @click="actionClicked(selectionActions[0].action)"
                >{{ selectionActions[0].label }}</p-button
            >
        </b-col>
    </div>
</template>

<script>
export default {
    name: "SelectionActions",
    props: {
        selectionActions: {
            type: Array,
            default: () => []
        }
        ,
        selectedRowCount: {
            type: Number,
            default: 0
        },
    },
    methods: {
        actionClicked(action) {
            this.$emit('action-clicked', action);
        }
    }
}
</script>
