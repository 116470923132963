<template>
<!--for b-form checkbox
    @input Emitted when the selected value(s) is changed
    @change Emitted when selected value(s) is changed due to user interaction
-->
    <b-row>
        <b-col>
            <b-form-checkbox
                :switch="useSwitch"
                v-bind="$attrs"
                :name="name"
                v-model="isSelected"
                :checked="value"
                :class="{ 'compact-control' : compactFormat}"
                @change="$emit('change',value)"
                @input="handleInput($event)"
                :indeterminate="indeterminate"
                >
            {{label}}
            </b-form-checkbox>
        </b-col>
        <b-col v-if="$slots.append" class="ml-auto pl-0">
            <slot name='append'></slot>
        </b-col>
    </b-row>
</template>
<script>

import baseInputs from '@/components/mixins/BaseInputs';

export default {
  name: "p-checkbox",
  mixins: [baseInputs],
  props: {
    value:  [Boolean],
    compactFormat: Boolean,
    useIndeterminate: {
      type: Boolean,
      default: false,
    },
    useSwitch:{
      type: Boolean,
      default: false,
    }

  },
  data() {
    return {
      isSelected: false,
    };
  },
  watch: {
    value: {
      handler: function(newVal) {
        //keep isSelected up to date in case this
        //property was updated outside of this component
        this.isSelected = newVal;
      }
    }
  },
  computed: {
    indeterminate() {
        return this.useIndeterminate && this.value === null;
    },
  },
  mounted: function() {
      this.isSelected = this.value;
  }
};
</script>
<style scoped lang="scss">
/deep/ .compact-control {
    min-height: 20px;
}
</style>



