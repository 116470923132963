<template>
    <div>
        <b-form-group class="pb-0 mb-0" >
      <template v-slot:label>
        {{labelText}}

            <span class="float-right">
        <b-button v-if="clearButton"
                    variant="light"
                    size="sm"
                    class="py-0"
                    v-show="hasSelection"
                    @click="clearSelections"
                    >Clear
                    <b-icon-x ></b-icon-x
                ></b-button>
            </span>
      </template>
            <p-input size="sm" v-model="userFilter" v-show="this.userFilter != '' || filteredOptions.length > initialDisplayCount" type="search"> </p-input>
            <div :class='{"option-list" : true,"tall-list" : tallList, "mt-1" : true}' >
                <b-checkbox v-for="option in visibleSelectedOptions"
                            :key="option.value"
                            :name="name + option.value"
                            v-model="selected"
                            class="option-list"
                            :value="option.value"
                            @input="handleInput($event)">
                    {{ option[itemText] }}
                </b-checkbox>
                <hr v-show="showDivider" class="m-1" />
                <b-checkbox v-for="option in visibleUnSelectedOptions"
                            :key="option.value"
                            :name="name + option.value"
                            v-model="selected"
                            class="option-list"
                            :value="option.value"
                            @input="handleInput($event)">
                    {{ option[itemText] }}
                </b-checkbox>
                <div v-if="filteredOptions.length === 0">
                    {{noMatchMessage}}
                </div>
                <div v-if="additionalSelectionMessage">
                    {{ additionalSelectionMessage }}
                </div>
            </div>
            <div class="text-center mt-1">
                <b-button
                    variant="light"
                    size="sm"
                    class="py-0"
                    v-show="!showAllOptions && hasHidableItems"
                    @click="showAllOptions = true"
                    >Show More
                    <b-icon-chevron-down></b-icon-chevron-down
                ></b-button>
                <b-button
                    variant="light"
                    size="sm"
                    class="py-0"
                    v-show="showAllOptions && hasHidableItems"
                    @click="showAllOptions = false"
                    >Show Less
                    <b-icon-chevron-up></b-icon-chevron-up
                ></b-button>
            </div>
        </b-form-group>
    </div>
</template>

<script>
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';
import baseInputs from '@/components/mixins/BaseInputs';
import {textIncludes} from "@/components/Common/Formatters.js";
import store from '@/store/store.js';

export default {
    name: 'p-filter-select',
    mixins: [baseInputs],
    props: {
        value: [Array],
        options: Array,
        dataType: String,
        filter: [Array,Function],
        defaultSelected: {
            type: Boolean,
            default:false
        },

        clearButton: {
            type: Boolean,
            default: true
        },
        tallList: {
            type: Boolean,
            default: false
        },

        itemText: {
            type: String,
            default: "text"
        },

        initialDisplayCount: {
            type: Number,
            default: 3
        },
        // Instead of using the value for the selected value, allow the text to be used.
        // Useful if someone wants to allow selection of a name that could possibly be duplicate
        // and don't want to differentiate between which specific version of 'bob smith' was selected (will handle removing duplicate options)
        useTextForValue: {
            type: Boolean,
            default: false
        },
        noMatchMessage: {
            type: String,
            default: "No Matching Options"
        },
        clearInvalidSelections: {
            //works with the filter, if a previously selected option
            //get's filtered out, it is cleared from the selection

            //set to false if you would like to remember selections even though they
            //have been filtered out.
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            innerOptions: [],
            showAllOptions: false,
            selected: [],
            userFilter: ''
        };
    },
    async created() {
        if (this.dataType) {
            // can't do this in a computed property because b-form-select :options requires an Array, not a Promise.
            this.innerOptions = await this.loadList(this.dataType);
            this.unsubscribe = store.subscribe((mutation) => {
                if (this.dataType && mutation.type.toLowerCase().includes(this.dataType.toLowerCase()) && mutation.payload.length > 0) {
                    this.innerOptions = mutation.payload;
                    this.setInnerOptionsToObjects();
                }
            });
        } else {
            this.innerOptions = this.options;
        }
        this.setInnerOptionsToObjects();

        if (this.defaultSelected) {
            this.selected = this.innerOptions.map(x => x.value);
            this.handleInput(this.selected);
        }
        //load any initial selections
        else {
            this.selected = this.value;
        }
    },
    beforeDestroy() {
        if(this.unsubscribe)
        {
            this.unsubscribe();
        }
    },
    computed: {
        stringFilter(){
            return this.filter.map(String);
        },
        showDivider() {
            return (
                this.visibleSelectedOptions.length > 0 &&
                this.visibleUnSelectedOptions.length > 0
            );
        },
        hasHidableItems(){
            if(this.filteredSelections.length === (this.initialDisplayCount + 1)
             &&  this.filteredSelections.length === this.filteredOptions.length ) {
                return false;
            }
            return  this.filteredOptions.length > this.initialDisplayCount;
        },
        additionalSelectionMessage() {
            if (
                this.visibleSelectedOptions.length <
                this.filteredSelections.length
            ) {
                return `+ ${this.filteredSelections.length -
                    this.visibleSelectedOptions.length} Selections`;
            }
            return false;
        },

        visibleSelectedOptions() {
            let options = this.filteredSelections;
            if (!this.showAllOptions) {
                options = options.slice(
                    0,
                    this.initialDisplayCount + 1 >= options.length
                        ? options.length
                        : this.initialDisplayCount
                );
            }
            return options;
        },
        filteredSelections() {
            return this.filteredOptions.filter(x => this.isSelected(x));
        },
        allSelectedOptions() {
            return this.innerOptions.filter(x => this.isSelected(x));
        },
        hasSelection(){
            return this.innerOptions.some(x => this.isSelected(x));
        },
        visibleUnSelectedOptions() {
            let options = this.filteredOptions.filter(x => !this.isSelected(x));
            if (!this.showAllOptions) {
                options = options.slice(
                    0,
                    this.initialDisplayCount -
                        this.visibleSelectedOptions.length >
                        0
                        ? this.initialDisplayCount -
                              this.visibleSelectedOptions.length
                        : 0
                );
            }
            return options;
        },
        filteredOptions() {
            let filteredOptions = this.innerOptions;
            if(this.filter){
            
                if (typeof this.filter == 'function') {
                    filteredOptions = filteredOptions.filter(x =>
                        this.filter(x)
                    );
                } else {
                    filteredOptions = filteredOptions.filter(x => this.stringFilter.indexOf(x.filter) > -1 );
                }
            }

            if (this.userFilter != '') {
                filteredOptions = filteredOptions.filter(
                    x => textIncludes(x[this.itemText],this.userFilter)
                );
            }
            return filteredOptions;
        }
    },

    watch: {
        filter: {
            handler: function() {
                if(this.clearInvalidSelections){
                    let selections = this.allSelectedOptions
                        .filter(x =>this.isSelected(x) && 
                          typeof this.filter == 'function' ?  this.filter(x) : this.stringFilter.indexOf(x.filter) > -1 )
                        .map(x => x.value);
                    //if value isn't a valid option, clear it.
                    this.selected = selections;
                    this.handleInput(selections);
                }
            },
        },
        options: {
            handler: function() {
                this.innerOptions = this.options;
                this.setInnerOptionsToObjects();
            },
            deep: true
        }

    },
    methods: {
        clearSelections(){
            this.selected = [];
            this.handleInput(this.selected)
        },
        isSelected(item) {
            return this.value.some(x => x === item.value);
        },
        async loadList(dataType) {
            return await selectListOptionsDataContext.getStoreDropdownData(
                dataType
            );
        },

        setInnerOptionsToObjects(){
            //to match bootstrap selects
            //If option array is a list of strings, it will be used for both the generated value and text fields.
            if(this.innerOptions.length > 0 && typeof(this.innerOptions[0]) === "string"){
                this.innerOptions = this.innerOptions.map((x) => ({text: x, value:x}));
            }
            if(this.useTextForValue){
                //remove possible duplicates.  For example if we have instructor Amy Smith multiple times with 
                //different Ids we will only want to keep 1 now that we are using the text as the value.
                this.innerOptions = [...new Map(this.innerOptions.map((item) => [item['text'], {...item}])).values()];
                this.innerOptions.forEach(x => {x.value = x.text});
            }
        },
    }
};
</script>

<style scoped>
>>> .option-list {
    max-height: 200px;
    overflow: auto;
}
>>> .option-list.tall-list {
    max-height: 500px;
    overflow: auto;
}
</style>
