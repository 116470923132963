<template>
    <ValidationProvider
        tag="div"
        :rules="rules"
        :name="label || name"
        :vid="vid"
        :mode="mode"
        v-slot="{ classes, errors }"
    >    
        <b-form-group
            :label-for="name || labelText"
            :label="labelText"
             :class="{ 'mb-0' : compactFormat || shortExpandableFormat, 'compact-container': compactFormat}"
        >
            <slot name="editButton"> </slot>
            <!-- bootstrap doesn't allow you to go below two rows.
            to provide a compact input for tables we can use a textarea, then the user has the option of increasing the size -->
            <textarea v-if="compactFormat || shortExpandableFormat" rows="1"
                    v-bind="$attrs"
                    :name="name"
                    :value="value"
                    @input="handleInput($event.target.value)"
                    @change="$emit('change',$event.target.value)"
                    :class="{...classes,'compact-control' : compactFormat, 'short-expandable-control' : shortExpandableFormat, 'form-control': true }"
            ></textarea>
            <b-form-textarea
                v-else
                v-bind="$attrs"
                :name="name"
                :value="value"
                @input="handleInput($event)"
                @change="$emit('change',$event)"
                :class="classes"
            ></b-form-textarea>
            <span>{{ errors[0] }}</span>
        </b-form-group>
    </ValidationProvider>
</template>

<script>
import debounce from 'lodash/debounce';
import baseInputs from '@/components/mixins/BaseInputs';
import validatedInput from '@/components/mixins/ValidatedInput';

export default {
    name: 'p-text-area',
    mixins: [baseInputs, validatedInput],
    props: {
        value: [String, Number],
        compactFormat: Boolean,
        shortExpandableFormat: {
            type: Boolean,
            default: false
        },
        debounceWait: {
            type: Number,
            default: 0
        }
    },
    created() {
        if (this.debounceWait > 0) {
            this.handleInput = debounce(this.handleInput, this.debounceWait);
        }
    }
};
</script>
<style scoped lang="scss">
/deep/ .compact-control {
    padding-bottom: 0px;
    padding-top: 0px;
    padding-left: 2px;
    height: 1.5rem;
    width:150px;
}
/deep/ .compact-container {
    width:150px;
}
/deep/ .short-expandable-control {
    box-sizing: border-box;
    height: 2.25rem;
    min-height: 2.25rem;
}
</style>
