import { HubConnectionBuilder, LogLevel, HttpTransportType } from '@aspnet/signalr';
import userManager from '../services/SecurityService';
import store from '../store/store.js';
import selectListOptionsDataContext from '@/services/selectListOptions.dataContext.js';

export default {
    install (Vue, options) {
      // use a new Vue instance as the interface for Vue components
      // to receive/send SignalR events. This way every component
      // can listen to events or send new events using this.$signalRHub
      const signalRHub = new Vue();
      Vue.prototype.$signalRHub = signalRHub;

      // Provide methods to connect/disconnect from the SignalR hub
       let connection = null;
       let startedPromise = null;
       let manuallyClosed = false;

     Vue.prototype.startSignalR = () => {
        userManager.getUser().then(function (user) {
            if (startedPromise || !user) return;

            connection = new HubConnectionBuilder()
            .withUrl(`${options.baseURL}clientNotificationHub`, { accessTokenFactory: () => user.access_token, transport: HttpTransportType.LongPolling })
            .configureLogging(LogLevel.Error)
            .build();

            connection.on('updateSelectList', async (listName) => {
                //Clear list so it will be forced to go back to the server to refresh the list.
                store.commit(listName, []);
                listName = listName.split('update')[1];
                listName = listName.charAt(0).toLowerCase() + listName.slice(1);
                await selectListOptionsDataContext.getStoreDropdownData(listName);
            });

            connection.on('buyersGuideLoaded', (isLoading) => {
                signalRHub.$emit('buyersGuideLoaded', isLoading);
            });

            // You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
            // Docs recommend listening onclose and handling it there.
            // This is the simplest of the strategies
            function start () {
                startedPromise = connection.start()
                    .catch(err => {
                    console.error('Failed to connect with hub', err)
                    return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000));
                    });
                return startedPromise;
            }
            connection.onclose(() => {
                if (!manuallyClosed) start();
            });

            // Start everything
            manuallyClosed = false;
            start();
        });
      };

      Vue.prototype.stopSignalR = () => {
        if (!startedPromise) return;

        manuallyClosed = true;
        return startedPromise
            .then(() => connection.stop())
            .then(() => { startedPromise = null });
      };
    }
  };