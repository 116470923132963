var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"w-100",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"pt-2"},[_c('b-row',[_c('b-col',[(_vm.includeSearchInput && _vm.filters && _vm.filters.options)?_vm._t("search-input",function(){return [_c('b-input-group',[_c('b-form-input',{ref:"searchInput",attrs:{"size":"md","name":"automatic","type":"search","hide-label":"","placeholder":_vm.searchMessageComputed,"debounce-wait":300},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();}},model:{value:(_vm.filters.automatic),callback:function ($$v) {_vm.$set(_vm.filters, "automatic", $$v)},expression:"filters.automatic"}}),_c('p-select',{staticClass:"ml-2",attrs:{"options":_vm.filters.options},model:{value:(_vm.filters.searchBy),callback:function ($$v) {_vm.$set(_vm.filters, "searchBy", $$v)},expression:"filters.searchBy"}})],1)]}):_vm._e()],2)],1),_c('b-row',[_c('b-col',[_vm._t("above-table")],2)],1),_c('b-row',[_c('b-table',_vm._b({ref:"searchResults",staticClass:"mr-md-3 ml-md-3",attrs:{"responsive":"","striped":"","hover":"","small":"","show-empty":"","sort-icon-left":"","no-sort-reset":"","selectable":_vm.selectable,"select-mode":_vm.selectMode,"empty-text":_vm.computedEmptyText,"api-url":_vm.searchUrl,"items":_vm.itemProvider,"fields":_vm.calculatedFields,"current-page":_vm.currentPage,"per-page":_vm.perPageSelection,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"row-clicked":function (e) { return _vm.enableRowSelection &&
                        !_vm.disableRowCheck(e) &&
                        _vm.rowClicked; },"sort-changed":_vm.sortChanged,"row-selected":function($event){return _vm.$emit('row-selected', $event)},"context-changed":_vm.updateSelectAll,"refreshed":_vm.updateSelectAll,"input":_vm.itemsChanged},scopedSlots:_vm._u([(_vm.showTopPaging)?{key:"thead-top",fn:function(){return [_c('b-tr',[_c('b-th',{staticClass:"pr-0",attrs:{"colspan":_vm.calculatedFields.length}},[_c('b-col',{staticClass:"d-flex pb-0 pt-0 pr-0"},[_c('div',{staticClass:"ml-auto mr-3"},[(
                                            _vm.showPerPageSelector &&
                                                _vm.totalRows > _vm.minPerPage
                                        )?_c('p-select',{attrs:{"options":_vm.perPageOptions},model:{value:(_vm.perPageSelection),callback:function ($$v) {_vm.perPageSelection=$$v},expression:"perPageSelection"}}):_vm._e()],1),_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"label":"Per Page","per-page":_vm.perPageSelection},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)]},proxy:true}:null,{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}}),{key:"head(selected)",fn:function(data){return [(!_vm.singleSelect)?_c('p-checkbox',{attrs:{"value":_vm.allSelected,"label":_vm.selectionLabel},on:{"change":_vm.toggleSelectAll}}):_c('span',[_vm._v(_vm._s(_vm.singleSelectButtonLabel))])]}},{key:"cell(selected)",fn:function(ref){
                                        var value = ref.value;
                                        var item = ref.item;
return [(!_vm.singleSelect)?_c('p-checkbox',{attrs:{"value":_vm.isSelected(item),"disabled":_vm.disableRowCheck(item)},on:{"change":function($event){return _vm.onSelection(item)}}}):_c('p-button',{attrs:{"size":"sm"},on:{"click":function($event){return _vm.onAddSelection(item)}}},[_vm._v(_vm._s(_vm.singleSelectButtonLabel))])]}},{key:"cell(dualSelected)",fn:function(ref){
                                        var value = ref.value;
                                        var item = ref.item;
return [_c('p-button',{attrs:{"size":"sm","variant":"outline-primary","disabled":_vm.disableSingleSelectButton},on:{"click":function($event){return _vm.onAddSelection(item)}}},[_vm._v(_vm._s(_vm.singleSelectButtonLabel))])]}},{key:"table-caption",fn:function(){return [_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"d-flex py-0 pr-0 "},[_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.totalRows)+" Results")]),_c('div',{staticClass:"ml-auto mr-3"},[(_vm.showPerPageSelector && _vm.totalRows > _vm.minPerPage)?_c('p-select',{attrs:{"options":_vm.perPageOptions},model:{value:(_vm.perPageSelection),callback:function ($$v) {_vm.perPageSelection=$$v},expression:"perPageSelection"}}):_vm._e()],1),(_vm.showBottomPaging)?_c('b-pagination',{attrs:{"total-rows":_vm.totalRows,"label":"Per Page","per-page":_vm.perPageSelection},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)]},proxy:true}],null,true)},'b-table',_vm.$attrs,false))],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }