<template>
    <div>
        <b-row cols="1" cols-lg="2">
            <b-col>
                <p-datepicker
                    label= "From Date"
                    vid="startDate"
                    v-model="dateRangeOptions.fromDate"
                    :disabled="disabled"
                    rules="required|dateGreaterOrEqual:@startDate"
                />
            </b-col>
            <b-col>
                <p-datepicker
                    label= "To Date"
                    vid="endDate"
                    v-model="dateRangeOptions.toDate"
                    rules="required|dateGreaterOrEqual:@startDate"
                    :disabled="disabled"
                    is-end-date
                    />
            </b-col>
        </b-row>
    </div>
</template>
<script>
export default {
    name: 'DateRangeAdvanceTemplate',
    components: {},
    props: {
        dateRangeOptions: {
            fromDate: null,
            toDate: null,
        },
    },
    data() {
        return {
            disabled: false,
        };
    },
    methods: {
        async loadData() {
            
        },
    },
    mounted: async function() {
        await this.loadData();
    },
};
</script>
